import React from "react";
import { Modal } from "antd";
import { IconClose } from "../../../utils/Icons";

const ModalUi = ({
  children,
  title,
  showModal,
  setShowModal,
  className,
  ...props
}) => {
  return (
    <Modal
      title={`${title}`}
      open={showModal}
      centered
      footer={null}
      onCancel={setShowModal}
      className={className}
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default ModalUi;
