import React, { useState, useMemo, useContext } from "react";
import dayjs from "dayjs";
import { Empty } from "antd";
import getDocTitle from "../../../utils/getDocTitle";
import { CardUi, SpinUi } from "../../../components/ui";
import { BINANCE_ARRAY } from "../../../constants/ExchangeArray";
import getDocsEnumWithIDs from "../../../utils/getDocsEnumWithIDs";
import usePurchaseHistory from "../../../hooks/usePurchaseHistory";
import { DocumentsContext } from "../../../contexts/DocumentsProvider";
import PurchaseHistoryModal from "../../../components/Modal/PurchaseHistoryModal";

const PurchaseHistoryItems = ({ slice, filter }) => {
  const { data, isLoading } = usePurchaseHistory();
  const { products, isLoading: isProductsLoading } =
    useContext(DocumentsContext);
  const DOCS_ENUM = useMemo(() => getDocsEnumWithIDs(products), [products]);

  const [showModal, setShowModal] = useState(false);
  const [historyData, setHistoryData] = useState(BINANCE_ARRAY[0]);
  const [start, end] = slice || [0, data?.data?.data?.length || 0];

  const historyItems = filter
    ? filter(data?.data?.data?.toReversed())?.slice(start, end)
    : data?.data?.data?.toReversed()?.slice(start, end);

  return (
    <>
      <div className="items">
        {isLoading || isProductsLoading ? (
          <SpinUi size={54} />
        ) : historyItems.length === 0 ? (
          <Empty className="big" />
        ) : (
          historyItems.map((item, i) => {
            const doc = { ...item, ...DOCS_ENUM[item.product_id] };
            return (
              <CardUi
                key={i}
                type="second"
                title={getDocTitle(doc)}
                price={parseFloat(item.summa)}
                icon={doc.icon}
                date={dayjs(item.createdAt).format("DD.MM.YYYY hh:mm A")}
                preImg={doc.preImg}
                onClick={() => {
                  setHistoryData(doc);
                  setShowModal(true);
                }}
              />
            );
          })
        )}
      </div>

      <PurchaseHistoryModal
        historyData={historyData}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default PurchaseHistoryItems;
