import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { IconWallet } from "../../utils/Icons";

const HowWorks = () => {
  const { t } = useTranslation();
  const basePath = "home.works.";

  return (
    <div className="how-work">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconWallet width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={24} xs={24}>
          <div className="item">
            <img src={require("../../assets/img/steps/step-1.png")} alt="" />
            <h4>{t(`${basePath}item1.title`)}</h4>
            <p>{t(`${basePath}item1.desc`)}</p>
          </div>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <div className="item">
            <img src={require("../../assets/img/steps/step-2.png")} alt="" />
            <h4>{t(`${basePath}item2.title`)}</h4>
            <p>{t(`${basePath}item2.desc`)}</p>
          </div>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <div className="item">
            <img src={require("../../assets/img/steps/step-3.png")} alt="" />
            <h4>{t(`${basePath}item3.title`)}</h4>
            <p>{t(`${basePath}item3.desc`)}</p>
          </div>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <div className="item">
            <img src={require("../../assets/img/steps/step-4.png")} alt="" />
            <h4>{t(`${basePath}item4.title`)}</h4>
            <p>{t(`${basePath}item4.desc`)}</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HowWorks;
