import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { InputNumberUi } from "../../../../components/ui";
import { IconEdit, IconClose, IconCheckSquare } from "../../../../utils/Icons";
import PurchaseRestoreModal from "../../../../components/Modal/PurchaseEditPriseModal";

const EditPurchasePrise = ({
  prise,
  record,
  editPurchasePrise,
  setEditPurchasePrise,
  refetch,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    form.setFieldValue("prise", prise);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record.id, Boolean(editPurchasePrise)]);

  if (!editPurchasePrise || editPurchasePrise.id !== record.id) {
    return (
      <span className="d-flex align-items-center">
        {`${parseFloat(prise).toFixed(2)} ${record.currency}`}
        <Button
          title={t("shared.edit")}
          type="text"
          onClick={() => setEditPurchasePrise(record)}
          icon={<IconEdit width="20" height="20" fill="#00c39a" />}
        />
      </span>
    );
  }

  const value = form.getFieldValue("prise");

  return (
    <>
      <Form form={form} name="prise" layout="vertical" autoComplete="off">
        <div className="d-flex align-items-center">
          <InputNumberUi formItemClassName="mb-0" name="prise" type="number" />
          <Button
            type="text"
            htmlType="submit"
            onClick={() => setOpen(true)}
            icon={<IconCheckSquare width="20" height="20" fill="#00c39a" />}
          />
          <Button
            type="text"
            onClick={() => setEditPurchasePrise(null)}
            icon={<IconClose width="20" height="20" fill="#d94c48" />}
          />
        </div>
      </Form>

      <PurchaseRestoreModal
        showModal={open}
        onCancel={() => setOpen(false)}
        purchase={record}
        refetch={refetch}
        value={value}
        setEditPurchasePrise={setEditPurchasePrise}
      />
    </>
  );
};

export default EditPurchasePrise;
