import { createContext, useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";

import { SCANLY_API_ROUTES, scanlyApi } from "../api";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("access_token"));
  const [showAuthModal, setAuthShowModal] = useState(false);

  const {
    error: loginError,
    isPending: isUserLoading,
    isError: isLoginError,
    isSuccess,
    mutate: mutateUser,
    mutateAsync: mutateUserAsync,
  } = useMutation({
    mutationFn: () => scanlyApi.get(SCANLY_API_ROUTES.USER),
    onSuccess: (data) => {
      setUser(data.data.user_data);
    },
  });

  const logout = useCallback(() => {
    localStorage.removeItem("access_token");
    setUser(null);
    setToken(null);
  }, [setUser]);

  const value = {
    user,
    token,
    mutateUser,
    mutateUserAsync,
    setUser,
    setToken,
    isUserLoading,
    isLoginError,
    loginError,
    isSuccess,
    logout,
    showAuthModal,
    setAuthShowModal,
  };

  useEffect(() => {
    scanlyApi.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 403) {
          logout();
        }
        return Promise.reject(error);
      }
    );
    if (!token) {
      logout();
    }
  }, []);

  useEffect(() => {
    if (token) {
      mutateUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
