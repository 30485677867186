import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ModalUi } from "../ui";
import getDocName from "../../utils/getDocTitle";

const PurchaseConfirmModal = ({ showModal, onCancel, onConfirm, product }) => {
  const { t } = useTranslation();
  const title = getDocName(product);
  const price = product?.price_with_discount || product.price;
  const currency = product.currency;

  return (
    <ModalUi
      showModal={showModal}
      onCancel={onCancel}
      className={`modal-signup`}
      title={t("purchaseConfirmModalModal.title")}
    >
      <img src={require("../../assets/img/successfully.svg").default} alt="" />

      <p>{t("purchaseConfirmModalModal.text", { title, price, currency })}</p>

      <div className="d-flex justify-center gap-[15] w-full">
        <Button type="dashed" onClick={onConfirm}>
          {t("purchaseConfirmModalModal.confirm")}
        </Button>
        <Button type="dashed" className="cancel" onClick={onCancel}>
          {t("purchaseConfirmModalModal.cancel")}
        </Button>
      </div>
    </ModalUi>
  );
};

export default PurchaseConfirmModal;
