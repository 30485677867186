import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/mail/withdrawal/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202124"}
      />

      <h4 className="time-arrival">
        [Binance] Средства успешно выведены -{" "}
        {state.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
        (UTC)
        <span>Входящие</span>
      </h4>
      <h6 className="time-mail">
        {state.timeMail ? dayjs(state.timeMail).format("HH:mm") : "00:00"}
      </h6>
      <h5 className="status">Средства успешно выведены</h5>
      <p className="text">
        Вы успешно вывели {state.amount || 0} {state.currency} со своего счета.
        <br />
        <br />
        <s>
          <b>Адрес вывода средств:</b>{" "}
          <u>{state.wallet || "_______________"}</u>
        </s>
        <br />
        <s>
          <b>Идентификатор транзакции:</b>{" "}
          {state.transaction || "_______________"}
        </s>
      </p>
      <p className="text v2">
        Вы не инициировали это действие?{" "}
        <span>Пожалуйста, сбросьте пароль</span> и{" "}
        <span>немедленно свяжитесь со службой поддержки.</span>
      </p>
      <p className="text v3">
        Это автоматическое сообщение. Пожалуйста, не отвечайте на него.
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img binance mail withdrawal`,
});
