import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { InputUi, ModalUi } from "../ui";
import useAuth from "../../hooks/useAuth";
import useMessage from "../../hooks/useMessage";
import { IconEye, IconEyeSlash } from "../../utils/Icons";
import useUpdateUserSelf from "../../hooks/useUpdateUserSelf";

const Settings = ({ showModalSettings, setShowModalSettings }) => {
  const { t } = useTranslation();
  const basePath = "user.settings.";
  const { showErrorMessage } = useMessage();

  const { user, mutateUserAsync } = useAuth();
  const [state, setState] = useState({});
  const [form] = Form.useForm();

  const { mutate, isPending } = useUpdateUserSelf({
    onSuccess: async () => {
      try {
        const data = await mutateUserAsync();
        setDefaultValues(data.data.user_data);
      } catch (err) {
        showErrorMessage(err?.response?.data?.message || err);
      }
    },
  });

  const onChange = (evt) => {
    setState((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));
  };

  const setDefaultValues = (user) => {
    const values = {
      login: user.login,
      email: user.email,
      phone: user.phone,
      password: "",
      confirm: "",
    };
    form.setFieldsValue(values);
    return values;
  };

  const successForm = () => {
    const newData = {};
    for (const prop in state) {
      const value = state[prop];
      if (
        user[prop] !== state[prop] &&
        !["password", "groups", "confirm"].includes(prop)
      ) {
        newData[prop] = value;
      }

      if (prop === "password" && state.password) {
        newData[prop] = value;
      }
    }

    mutate(newData);
  };

  useEffect(() => {
    form.resetFields();
    setState(setDefaultValues(user));
  }, [showModalSettings]);

  return (
    <ModalUi
      destroyOnClose
      title={t(`${basePath}title`)}
      showModal={showModalSettings}
      className="modal-settings"
      setShowModal={() => {
        setShowModalSettings(false);
      }}
    >
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={successForm}
        autoComplete="off"
        onChange={onChange}
      >
        <InputUi
          name="login"
          label={t(`${basePath}name.label`)}
          placeholder={t(`${basePath}name.placeholder`)}
          errorMess={t(`${basePath}name.error`)}
        />

        <InputUi
          name="email"
          type="email"
          label={t(`${basePath}email.label`)}
          placeholder={t(`${basePath}email.placeholder`)}
          moreRules={{
            type: "email",
            message: t(`${basePath}email.valid`),
          }}
          errorMess={t(`${basePath}email.error`)}
        />

        <InputUi
          name="phone"
          label={t(`${basePath}phone.label`)}
          placeholder={t(`${basePath}phone.placeholder`)}
          errorMess={t(`${basePath}phone.error`)}
          required={false}
          moreRules={{
            pattern: /\+\s*(\d\s*){12}/,
            message: t("auth.register.phone.errorFormat"),
          }}
        />

        <InputUi
          name="password"
          type="password"
          label={t(`${basePath}pass.label`)}
          placeholder="*******"
          errorMess={t(`${basePath}pass.error`)}
          required={false}
        />

        <Form.Item
          name="confirm"
          label={t(`${basePath}passConfirm.label`)}
          dependencies={["password"]}
          rules={[
            {
              required: false,
              message: t(`${basePath}passConfirm.error`),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t(`${basePath}passConfirm.valid`))
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="*******"
            name="confirm"
            iconRender={(visible) =>
              visible ? (
                <IconEyeSlash width="20" height="20" fill="#fff" />
              ) : (
                <IconEye width="20" height="20" fill="#fff" />
              )
            }
          />
        </Form.Item>

        <div className="btn-group mt-30">
          <div className="btn-persv">
            <Button type="dashed" htmlType="submit" loading={isPending}>
              {t(`${basePath}btn`)}
            </Button>
          </div>
        </div>
      </Form>
    </ModalUi>
  );
};

export default Settings;
