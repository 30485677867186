import React from "react";
import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";

const Result = ({ type, state, mobile, coin }) => {
  const getCoinData = (id) => coin.find((item) => item.id === id)?.value || {};

  const btc = getCoinData("btc");
  const eth = getCoinData("eth");
  const usdc = getCoinData("usdc");

  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/wallet/deposit/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202630"}
      />

      <h2 className="amount">{`+${state.amount || 0} ${state.currency}`}</h2>
      <div className="network">{state.network}</div>
      <p className="content">{state.wallet || "_______________"}</p>
      <p className="content txid">{state.txid || "_______________"}</p>
      <p className="content spot">Спотовый кошелек</p>
      <p className="content date">
        {state?.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
      </p>
      <div className="course">
        <div className="course__item">
          {formattedNumber(btc.lastPrice, { min: 2 })}
          <span className={btc.priceChangePercent > 0 ? "green" : "red"}>
            {btc.priceChangePercent > 0 && "+"}
            {formattedNumber(btc.priceChangePercent)}%
          </span>
        </div>
        <div className="course__item">
          {formattedNumber(eth.lastPrice, { min: 2 })}
          <span className={eth.priceChangePercent > 0 ? "green" : "red"}>
            {eth.priceChangePercent > 0 && "+"}
            {formattedNumber(eth.priceChangePercent)}%
          </span>
        </div>
        <div className="course__item">
          {formattedNumber(usdc.lastPrice, { min: 2 })}
          <span className={usdc.priceChangePercent > 0 ? "green" : "red"}>
            {usdc.priceChangePercent > 0 && "+"}
            {formattedNumber(usdc.priceChangePercent)}%
          </span>
        </div>
      </div>
    </>
  );
};

export default withPurchase(Result, {
  className: `result-img binance wallet deposit`,
});
