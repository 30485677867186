import React from "react";
import { Collapse } from "antd";
import { IconAngleRightSquare } from "../../../utils/Icons";

const CollapseUi = ({ children, className, activeKey, ...props }) => {
  return (
    <Collapse
      className={className}
      defaultActiveKey={activeKey}
      expandIconPosition={"end"}
      expandIcon={({ isActive }) => (
        <IconAngleRightSquare
          width="22"
          height="22"
          fill="#00c39a"
          transform={`rotate(${isActive ? 90 : 0})`}
        />
      )}
      items={children}
      {...props}
    />
  );
};

export default CollapseUi;
