import {
  BINANCE_ARRAY,
  BYBIT_ARRAY,
  OKX_ARRAY,
  HTX_ARRAY,
} from "../constants/ExchangeArray";

import { SBERBANK_ARRAY, ALFA_ARRAY } from "../constants/BankArray";
import { TRUST_ARRAY, EXODUS_ARRAY } from "../constants/WalletsArray";
import { CRYPTOCURRENCY_ARRAY } from "../constants/CryptocurrencyArray";

const getDocsEnumWithIDs = (products) => {
  const docs = [
    ...BINANCE_ARRAY,
    ...BYBIT_ARRAY,
    ...OKX_ARRAY,
    ...HTX_ARRAY,
    ...SBERBANK_ARRAY,
    ...ALFA_ARRAY,
    ...CRYPTOCURRENCY_ARRAY,
    ...TRUST_ARRAY,
    ...EXODUS_ARRAY,
  ];

  return docs.reduce((accumulator, currentValue) => {
    const product = products.find(({ id }) => id === currentValue.api_id);
    return currentValue.api_id
      ? {
          ...accumulator,
          [currentValue.api_id]: { ...currentValue, ...product },
        }
      : accumulator;
  }, {});
};

export default getDocsEnumWithIDs;
