import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Dropdown, Input, Tooltip } from "antd";
import { DropdownBtn } from "./ui";
import { languages } from "../i18n";
import useAuth from "../hooks/useAuth";
import Settings from "./Modal/SettingsModal";
import { useLanguage } from "../contexts/LanguageContext";
import { SearchContext } from "../contexts/SearchProvider";
import {
  IconDocumentList,
  IconDollarReceive,
  IconHome,
  IconLogIn,
  IconMenuLeftSquare,
  IconSettings,
  IconShoppingCart,
  IconTimesSquare,
} from "../utils/Icons";

const langIcons = {
  "ru-RU": require("../assets/img/flags/ru.png"),
  "en-US": require("../assets/img/flags/en.png"),
};

const { Search } = Input;

const UserNavBar = ({ toggleSidebar, isSidebarActive }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "userNavBar.";

  const { user, logout } = useAuth();
  const { changeLanguage } = useLanguage();
  const activeLang = localStorage.getItem("i18nextLng");

  const history = useHistory();
  const { setSearchTerm } = useContext(SearchContext);

  const [showModalSettings, setShowModalSettings] = useState(false);

  const balances = user?.balances?.[0]?.balance;

  const languagesArr = languages.map((item, index) => ({
    key: index,
    label: (
      <span className="item-lang">
        <img src={langIcons[item]} alt="" />
        {t(`${basePath}lang.${item}`)}
      </span>
    ),
  }));

  const onLangChange = ({ key }) => {
    const lang = languages[+key];
    localStorage.setItem("i18nextLng", lang);
    changeLanguage(lang);
  };

  const items = [
    {
      key: "0",
      label: <Link to="/profile">{t(`${basePath}menu.profil`)}</Link>,
      icon: <IconHome width="18" height="18" fill="#ababab" />,
    },
    {
      key: "2",
      label: <Link to="/refill">{t(`${basePath}menu.refill`)}</Link>,
      icon: <IconDollarReceive width="18" height="18" fill="#ababab" />,
    },
    {
      key: "3",
      label: (
        <Link
          onClick={() => {
            setShowModalSettings(true);
          }}
        >
          {t(`${basePath}menu.setting`)}
        </Link>
      ),
      icon: <IconSettings width="18" height="18" fill="#ababab" />,
    },
    {
      key: "4",
      label: t(`${basePath}menu.logout`),
      icon: <IconLogIn width="18" height="18" fill="#ababab" />,
    },
  ];

  const onUserNavClick = ({ key }) => {
    if (key === "4") {
      logout();
    }
  };

  const onSearch = (value) => {
    if (value) {
      setSearchTerm(value);
      history.push("/all-docs");
    }
  };

  return (
    <>
      <div className="user-bar">
        <Button
          type="dashed"
          onClick={toggleSidebar}
          className={`toggle-sidebar-btn ${isSidebarActive ? "active" : ""}`}
        >
          {isSidebarActive ? (
            <IconTimesSquare width="24" height="24" fill="#00c39a" />
          ) : (
            <IconMenuLeftSquare width="24" height="24" fill="#00c39a" />
          )}
        </Button>
        <Search
          placeholder={t(`${basePath}search`)}
          enterButton
          allowClear={{
            clearIcon: (
              <IconTimesSquare width="14" height="14" fill="#d94c48" />
            ),
          }}
          onSearch={onSearch}
        />
        <div className="right-side">
          <Dropdown
            className={"lang"}
            menu={{ items: languagesArr, onClick: onLangChange }}
            trigger={["click"]}
          >
            <DropdownBtn>{t(`${basePath}lang.${activeLang}`)}</DropdownBtn>
          </Dropdown>
          <div className="balance">
            Баланс:
            <s>$ {Number(balances).toFixed(2)}</s>
          </div>
          <Tooltip
            placement="bottom"
            title={t(`${basePath}document`)}
            className="item-document"
          >
            <Link to="/all-docs" className="icon">
              <IconDocumentList width="22" height="22" fill="#fff" />
            </Link>
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={t(`${basePath}history`)}
            className="item-history"
          >
            <Link to="/history" className="icon">
              <IconShoppingCart width="22" height="22" fill="#fff" />
            </Link>
          </Tooltip>
          <Dropdown
            menu={{ items, onClick: onUserNavClick }}
            trigger={["click"]}
          >
            <DropdownBtn className={"user-menu"}>
              <div>
                <img src={require("../assets/img/user.svg").default} alt="" />
              </div>
            </DropdownBtn>
          </Dropdown>
        </div>
      </div>

      {/* Modal Settings */}
      <Settings
        showModalSettings={showModalSettings}
        setShowModalSettings={setShowModalSettings}
      />
    </>
  );
};

export default UserNavBar;
