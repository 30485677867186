import CoinsList from "./CoinsList";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";
import { IconAngleRightSmall } from "../../../../../utils/Icons";

function Result({ type, state, mobile, coins, coinsValues, usdBalance }) {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/wallet/main/binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202630"}
      />

      <div className="main-balance">
        <span>
          {formattedNumber(usdBalance, { max: 2 })} $
          <span className="currency">USD</span>
        </span>
      </div>

      <div className="pnl">
        PnL за сегодня{" "}
        <span className={`${state.pnl.charAt(0) === "-" ? "red" : "green"}`}>
          {state.pnl}
          <IconAngleRightSmall width="55" height="55" fill="#9fa3ab" />
        </span>
      </div>

      <CoinsList coins={coins} coinsValues={coinsValues} />
    </>
  );
}

export default withPurchase(Result, {
  className: "result-img binance wallet main",
});
