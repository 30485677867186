export const TRANSLATIONS_RU = {
  shared: {
    "edit": "Редактировать",
    "save": "Сохранить",
    "cancel": "Отменить",
    "restore": "Восстановить",
    "delete": "Удалить",
    filter: "Фильтровать",
    reset: "Сбросить",
  },

  navBar: {
    auth: "Авторизация",
    lang: {
      title: "Язык",
      "en-US": "Английский",
      "ru-RU": "Русский",
    },
    welcome: "Добро пожаловать",
    dropDown: {
      profile: "Профиль",
      logout: "Выйти",
    },
  },

  sideBar: {
    menu: {
      profil: "Главная",
      refill: "Пополнение",
      history: "История покупок",
      document: "Документы",
      ref: "Реферальная программа",
      setting: "Настройки",
      admin: "Админ панель",
      logout: "Выход",
    },
  },

  userNavBar: {
    search: "Поиск документов",
    document: "Документы",
    history: "История покупок",
    lang: {
      title: "Язык",
      "en-US": "Английский",
      "ru-RU": "Русский",
    },
    menu: {
      profil: "Главная",
      refill: "Пополнение",
      setting: "Настройки",
      logout: "Выход",
    },
  },

  adminBar: {
    menu: {
      main: "Главная",
      docs: 'Документы',
      users: 'Пользователи',
      purchases: "Покупки",
    }
  },

  apiErrors: {
    "User or password incorrect": "Неправильный пользователь или пароль",
    "User not found": "Пользователь не найден",
    "This email is already associated with another account":
      "Этот адрес электронной почты уже связан с другим аккаунтом",
    refillError:
      "Не удалось пополнить аккаунт. Пожалуйста,  Попробуйте еще раз.",
    "Amount less than allowed": "Сумма меньше разрешенной",
    "Required fields for this method amount, currency, system": "Обязательные поля для этого метода: сумма, валюта, система.",
    "User has insufficient balance for requested action": "Остаток на вашем балансе недостаточен для покупки",
    'User not active': 'Пользователь заблокирован. Обратитесь в технический отдел!'
  },

  auth: {
    title: "Авторизация",
    login: {
      title: "Вход",
      name: {
        label: "Логин",
        placeholder: "Введите Ваш логин",
        error: "Пожалуйста, введите логин",
      },
      pass: {
        label: "Пароль",
        error: "Пожалуйста, введите пароль",
      },
      btn: "Войти",
    },

    register: {
      title: "Регистрация",
      name: {
        label: "Логин",
        placeholder: "Введите Ваш логин",
        error: "Пожалуйста, введите логин",
      },
      phone: {
        label: "Телефон",
        placeholder: "Введите свой телефон",
        error: "Введите свой телефон",
        errorFormat: "Пожалуйста, напишите в формате +38 093 123 45 67"
      },
      email: {
        label: "Почта",
        placeholder: "Введите Ваш email",
        error: "Пожалуйста, введите email",
        valid: "Пожалуйста, введите корректный email",
      },
      pass: {
        label: "Пароль",
        error: "Пожалуйста, введите пароль",
      },
      passConfirm: {
        label: "Повторите пароль",
        error: "Пожалуйста, введите пароль",
        valid: "Введенный вами пароль не совпадает!",
      },
      btn: "Зарегистрироваться",
    },

    messages: {
      successSignUpAndLogin: "Вы успешно зарегистрировались и вошли в систему",
      successLogin: "Вы успешно вошли в систему",
    },

    signUpModal: {
      title: "Ошибка",
      beforeBuy: 'Прежде чем купить этот скриншот, пожалуйста, зарегистрируйтесь и пополните свой баланс.',
      btn: 'Зарегистрироваться',
    },
  },

  breadcrumb: {
    title: "Навигация:",
    list: {
      home: "Главная",
      sections: {
        exchange: "Криптовалютные Биржи",
        wallets: "Крипто Кошельки",
        bank: {
          title: "Банки",
          subSections: {
            sberbank: "Сбербанк",
            alfa: "Альфа-Банк",
          }
        },
        allDocs: "Все документы",
      },
    }
  },

  home: {
    banner: {
      desc: {
        first:
          "Онлайн-инструмент для создания Высококачественных изображений. Вы можете создавать скриншоты выводов, пополнений, транзакций всего за 2 минуты.",
        second:
          "Вы несете ответственность за законное использование сервиса. Используя сервис, вы должны быть осведомлены о местных законах, законах штата и федеральных законах в вашей юрисдикции и нести единоличную ответственность за свои действия.",
      },
      btn: "Перейти к инструменту",
    },

    itemList: {
      exchanges: {
        title: "Криптовалютные Биржи",
        descFirst:
          "Уникальный сервис генерации персонализированных скриншотов! Эта категория предоставляет вам возможность самостоятельно настраивать и создавать скриншоты, отражающие именно те данные и аспекты торговых платформ, которые вам интересны.",
        descSecond:
          "Неограниченные возможности настройки делают эту категорию идеальным инструментом для тех, кто ищет удобный способ поделиться своим опытом, обучать других или просто создавать запоминающиеся визуальные отчеты о своих торговых успехах. Давайте делиться уникальными моментами и инсайтами с миром криптовалютной торговли!",
      },
      wallet: {
        title: "Крипто Кошельки",
        descFirst:
          "Инновационный сервис по генерации персонализированных скриншотов для ваших кошельков! Эта категория предоставляет вам уникальную возможность самостоятельно настраивать и создавать скриншоты, отображающие те детали и транзакции, которыми вы хотите поделиться или сохранить.",
        descSecond:
          "Выбирайте любые параметры, начиная от визуального оформления и заканчивая деталями транзакций. Вы можете изменять суммы, имена активов, даты и многое другое, чтобы создать идеальное изображение для вашего виртуального кошелька. Этот сервис разработан так, чтобы предоставить вам полный контроль над создаваемыми скриншотами, обеспечивая высший уровень персонализации.",
      },
      other: {
        title: "Различные документы",
        bank: {
          title: "Банки",
          desc: 'Категория "Банки" предоставляет пользователям динамическую платформу для моделирования и создания различных скриншотов, связанных с банковской деятельностью. Пользователи могут создавать реалистичные изображения транзакций, переводов, депозитов и других финансовых операций, вводя настраиваемые данные.',
        },
        doc: {
          title: "Документы",
          desc: 'Категория "Документы" предлагает пользователям универсальную платформу для создания реалистичных скриншотов различных документов, удостоверяющих личность. От паспортов и удостоверений личности до сертификатов и водительских прав - пользователи могут вводить любые необходимые данные и создавать индивидуальные визуальные представления.',
        },
        tools: {
          title: "Инструменты",
          desc: 'Категория "Инструменты" представляет собой универсальный набор инструментов для создания скриншотов, предлагая такие функции, как штрих-коды, QR-коды, лица людей и т.д. Пользователи могут вводить и настраивать данные в соответствии со своими требованиями, что позволяет создавать персонализированные и реалистичные изображения.',
        },
      },
      btn: "Подробней",
    },

    advantages: {
      items: [
        {
          icon: "flex",
          title: "Гибкость",
        },
        {
          icon: "cont",
          title: "Контроль",
        },
        {
          icon: "auto",
          title: "Автоматизация",
        },
        {
          icon: "conv",
          title: "Удобство",
        },
        {
          icon: "fast",
          title: "Быстрота",
        },
        {
          icon: "supp",
          title: "Поддержка",
        },
      ],
    },

    works: {
      title: "Как это работает",
      item1: {
        title: "Регистрация",
        desc: "Создайте учетную запись, чтобы начать работу с сервисом.",
      },
      item2: {
        title: "Пополнение",
        desc: "Внесите средства на баланс для доступа к платным функциям.",
      },
      item3: {
        title: "Выбор документа",
        desc: "Выберите необходимый документ из доступных вариантов.",
      },
      item4: {
        title: "Генерация документа",
        desc: "Настройте и получите готовый документ в нужном формате.",
      },
    },
  },

  formMain: {
    title: "Заполните информацию",
    price: "Цена:",
    system: "Операционная система:",
    theme: {
      title: "Оформление темы:",
      dark: "Темное",
      light: "Светлое",
    },

    formCollapse: {
      phone: "Настройка телефона",
      content: "Настройка контента",
    },

    timePhone: "Время на телефоне",
    mobNetwork: "Сотовая связь",
    mobConnect: "Интернет",
    battery: {
      label: "Заряд батареи",
      charge: "На зарядке",
    },
    additional: {
      label: "Дополнительные элементы",
      placeholder: "Выберите дополнительные элементы",
      none: "Отключить",
      geo: "Геопозиция",
      bother: "Не беспокоить",
    },
    eSim: "Активировать eSim",

    transfer: {
      all: "Валюты",
      select: "Выбранные валюты",
    },

    btn: {
      png: "Скачать PNG",
      jpeg: "Скачать JPEG",
    },

    message: {
      success: "Файл успешно сгенерирован, ожидайте загрузки!",
      error: "Файл не сгенерирован, попробуйте снова!",
      requiredField: "Это поле обязательно к заполнению!",
    },
  },

  card: {
    price: 'Цена',
    os: "OC",
  },

  exchange: {
    title: "Криптовалютные Биржи",
    modal: {
      label: "Выберите операционную систему",
    },

    form: {
      binance: {
        timeMail: "Время прихода сообщения на почту",
        timeArrival: "Дата и время создание заявки на вывод",
        amount: "Сумма",
        currency: "Валюта",
        network: "Сеть",
        wallet: "Кошелек",
        pnl: "PnL за сегодня",
        commission: "Комиссия сети",
        transaction: "ID Транзакции",
        averCost: "Средняя стоимость",
        coinCount: "Введите количество валюты",
        timeOperation: "Дата и время транзакции",
        balance: "Балансы",
        selectCoin: "Выберите валюты",
      },

      bybit: {
        assets: {
          sport: "Спот",
          general: "Общие активы",
          financing: "Финансирования",
          directives: "Деривативы",
        },

        currency: "Валюта",
        wallet: "Кошелек",
        network: "Вид сети",
        amount: "Сумма",
        commission: "Комисия",
        address: "Адрес депозита",
        account: "Аккаунт депозита",
        hash: "Хэш транзакции (TXID)",
        timeOperation: "Дата и время транзакции",
        coinCount: "Введите количество валюты",
        timeMail: "Время прихода сообщения на почту",
        depositAmount: "Cумма депозита",
        timeArrival: "Дата и время создание заявки на вывод",
        balance: "Балансы",
        selectCoin: "Выберите валюты",
      },

      okx: {
        time: "Время",
        amount: "Сумма",
        currency: "Валюта",
        fee: "Комиссия",
        blockchain: "Блокчейн",
        withdrawalID: "ID вывода",
        reference: "Справочный Nº",
        addressDomain: "Адрес / домен",
        transactionID: "ID транзакции",
        timeOperation: "Дата и время транзакции",
        timeMail: "Время прихода сообщения на почту",
        balance: "Балансы",
        selectCoin: "Выберите валюты",
        coinCount: "Введите количество валюты",
      },

      htx: {
        fee: "Комиссия",
        network: "Вид сети",
        wallet: "Адрес вывода",
        currency: "Валюта",
        hash: "Хэш транзакции (TXID)",
        depositAmount: "Cумма депозита",
        withdrawalAmount: "Cумма вывода",
        pnlUsd: "PnL в ($)",
        pnlRate: "PnL в (%)",
        generalPnlUsd: "PnL общий в ($)",
        generalPnlRate: "PnL общий в (%)",
        timeOperation: "Дата и время транзакции",
        timeMail: "Время прихода сообщения на почту",
        balance: "Балансы",
        selectCoin: "Выберите валюты",
        coinCount: "Введите количество валюты",
      },
    },
  },

  wallets: {
    title: "Крипто Кошельки",
    modal: {
      label: "Выберите операционную систему",
      currency: {
        label: "Выберите валюту",
      },
    },

    form: {
      trust: {
        time: "Время",
        wallet: "Получатель",
        commission: "Комиссия",
        currency: "Валюта",
        amount: "Введите общую сумму",
        amountTransf: "Введите сумму перевода",
        balance: "Балансы",
        selectCoin: "Выберите валюты",
        coinCount: "Введите количество валюты",
        date: {
          label: "Дата",
          plac: "янв. 1 2023",
        },

        nameWallet: {
          label: "Название кошелька",
          placeholder: "Основной Кошелек",
        },

        status: {
          label: "Статус операции",
          plac: "Выберите статус операции",
          elem: {
            copm: "Выполнено",
            pend: "В обработке",
            fail: "Неудачно",
          },
        },

        operation: {
          label: "Тип операции",
          plac: "Выберите тип операции",
          elem: {
            rece: "Отправитель",
            sent: "Получатель",
            receSecond: "Получено",
            sentSecond: "Отправлено",
          },
        },

        list: {
          title: "Транзакции:",
          amount: "Сумма",
          wallet: "Кошелек",
          date: {
            label: "Дата",
            plac: "янв. 1 2023",
            today: "Этой же датой",
          },

          type: {
            label: "Тип операции",
            plac: "Выберите тип операции",
            elem: {
              rece: "Получено",
              sent: "Отправлено",
            },
          },

          btn: {
            add: "Добавить транзакцию",
            remove: "Удалить транзакцию",
          },
        },
      },

      exodus: {
        amount: "Сумма | Валюта",
        amountCoin: "Количество монет",
        currency: "Валюта",
        network: "Сеть",
        noNet: 'Нет сети',
        fee: "Сбор",
        wallet: "Кошелек",
        notes: "Личные заметки",
        date: "Дата транзакции",
        txid: "ID Транзакции",
        amountNow: "Сумма (Сейчас)",
        amountOld: "Сумма (На момент транзакции)",
        infoAmountOld:
          "Сумма указывается, на тот момент когда была произведена транзакция, в случаи если транзакция была сегодня то поле не активно",
      },
    },
  },

  bank: {
    title: "Банки",
    modal: {
      label: "Выберите операционную систему",
      currency: {
        label: "Выберите валюту",
      },
    },

    form: {
      sberbank: {
        title: 'Сбербанк',
        cardholder: "Имя держателя карты",
        balance: "Баланс на карте",
        fullName: "ФИО получателя",
        sender: "Отправитель",
        fullDate: "Дата и время",
        balanceBill: "Баланс на счету",
        amountTransfer: "Сумма перевода",
        recipientCard: "Номер карты получателя",
        lastCard: "Последние 4 цифры карты",
        lastBill: "Последние 4 цифры счета",
        lastCardDebit: "Последние 4 цифры карты списания",
        lastCardEnroll: "Последние 4 цифры карты зачисления",
      },

      tinkoff: {
        title: 'Тинькофф банк',
      },

      alfa: {
        title: 'Альфа-банк',
        fullDate: "Дата и время платежа",
        balance: "Баланс на карте",
        senderСard: "Карта отправителя",
        cardholder: "Имя держателя карты",
        lastCard: "Последние 4 цифры карты",
        lastBill: "Последние 4 цифры счета",

        operation: {
          label: "Тип перевода",
          type: {
            one: "Тип перевода #1",
            oneValue: "type1",
            two: "Тип перевода #2",
            twoValue: "type2",
            three: "Тип перевода #3",
            threeValue: "type3",
          },
        },

        cards: {
          title: "Карты",
          type: "Тип карты",
          lastCard: "Последние 4 цифры карты",

          btn: {
            add: "Добавить карту",
            remove: "Удалить карту",
          },
        },
      },

      gazprom: {
        title: 'Газпромбанк',
      },
    },
  },

  user: {
    profil: {
      welcome: {
        morning: "Доброе утро",
        afternoon: "Добрый день",
        evening: "Добрый вечер",
      },

      balance: "Баланс",
      refill: "Пополнить",
      quantityPurchases: "Количество покупок",
      top: "Топ 3 документов",
      propose: {
        title: "Предложить документ",
        btn: "Предложить",
      },
      lastPurchases: "Последние покупки",
      modal: {
        btn: "Скачать",
      },
      copyWallet: "Копировать кошелек",
      walletCopied: "Кошелек скопирован в буфер обмена",
      failedCopyWallet: "Не удалось скопировать кошелек в буфер обмена",
    },

    history: {
      title: "История покупок",
      modal: {
        btn: "Скачать",
      },
    },

    refill: {
      waiting: "Запрос отправлен в платежную систему. Ждем ответ.",
      completed: "Платеж успешный",
      title: "Пополнение баланса",
      form: {
        amount: "Введите Сумму",
        system: "Выберите Систему",
        network: "Выберите Сеть",
        btn: "Пополнить",
        info: "Заявка обрабатывается 10 - 20 минут после фактического зачисления средств (набора 2 подтверждений(ия) и зачисления на счет биржи).",
      },
      modal: {
        title: 'Перевод',
        qr: 'QR-код:',
        transfer: 'Переведите указанную сумму -',
        wallet: 'На данные реквизиты:',
        info: 'Убедитесь, что реквизиты получателя указаны верно!',
      },
      lastOperations: {
        title: "История пополнений",
        amount: "Сумма:",
        system: "Система:",
        date: "Дата:",
        status: 'Статус',
      },
    },

    settings: {
      title: "Редактировать профиль",
      name: {
        label: "Логин",
        placeholder: "Введите Ваш логин",
        error: "Пожалуйста, введите логин",
      },
      email: {
        label: "Почта",
        placeholder: "Введите Ваш email",
        error: "Пожалуйста, введите email",
        valid: "Пожалуйста, введите корректный email",
      },
      phone: {
        label: "Номер телeфона",
        placeholder: "Введите Ваш номер телeфона",
        error: "Пожалуйста, введите номер телeфона",
      },
      pass: {
        label: "Пароль",
        error: "Пожалуйста, введите пароль",
      },
      passConfirm: {
        label: "Повторите пароль",
        error: "Пожалуйста, введите пароль",
        valid: "Введенный вами пароль не совпадает!",
      },
      btn: "Сохранить",
      updateSuccess: "Ваш профиль был успешно обновлен",
    },

    admin: {
      docs: {
        card: {
          nameRu: "Наименование (RU):",
          nameEng: 'Наименование (ENG):',
          price: "Цена:",
          priceDiscount: "Цена (Скидка):",
          sectionRu: "Раздел (RU):",
          sectionEng: "Раздел (ENG):",
          subsectionRu: 'Подраздел (RU):',
          subsectionEng: "Подраздел (ENG):",
          btn: "Изменить",
          productVisibility: "Видимость продукта",
          active: "Показано",
          hidden: "Скрытый",
          discountNone: "Отсутствует",

          edit: {
            editProduct: "Изменить документ",
            save: "Сохранить",
            discount: "Активировать скидку",
            updated: "Документ изменен",
            notUpdated: "Документ не был изменен",
          }
        }
      },

      usersList: {
        table: {
          title: "Пользователи",
          search: "Поиск пользователя",
          login: "Логин",
          balance: "Баланс",
          actions: {
            title: "Действия",
            tooltip: "Редактировать",
          },
        },
      },

      purchaseList: {
        table: {
          title: "Документ",
          price: "Цена",
          deleted: "Удалено",
          createdAt: "Создано",
          actions: "Действия",
          user: "Пользователь",
          restore: "Восстановить документ",
          delete: "Удалить документ",
          deleteYes: 'Да',
          deleteNo: 'Нет',
          modal: {
            title: "Подтвердите удаление покупки",
            titleRestore: "Подтвердите восстановление покупки",
            titleEdit: 'Подтвердите изменение цены',
            text: "Пользователь: <strong>{{ user }}</strong> <br> Дата: <strong>{{ time }}</strong> <br> Документ: <strong>{{ title }}</strong> <br><br> Покупка будет удалена после подтверждения. Пожалуйста, подтвердите свое действие",
            textRestore: "Пользователь: <strong>{{ user }}</strong> <br> Дата: <strong>{{ time }}</strong> <br> Документ: <strong>{{ title }}</strong> <br><br> Покупка будет восстановлена после подтверждения. Пожалуйста, подтвердите свое действие",
            textEdit: "Пользователь: <strong>{{ user }}</strong> <br> Дата: <strong>{{ time }}</strong> <br> Документ: <strong>{{ title }}</strong> <br> Изменение цены с <strong>{{ from }}</strong> на <strong>{{ to }}</strong>. <br><br> Пожалуйста, подтвердите ваше действие.",
            deleteSuccess: "Покупка успешно удалена",
            deleteError: "Ошибка удаления покупки",
            restoreSuccess: "Покупка успешно восстановлена",
            restoreError: "Ошибка восстановления покупки",
            priceEditSuccess: "Цена покупки успешно изменена.",
            priceEditError: "Ошибка при изменении цены покупки."
          }
        }
      },

      editUser: {
        title: "Редактировать профиль",
        name: {
          label: "Логин",
          placeholder: "Введите Ваш логин",
          error: "Пожалуйста, введите логин",
        },
        email: {
          label: "Почта",
          placeholder: "Введите Ваш email",
          error: "Пожалуйста, введите email",
          valid: "Пожалуйста, введите корректный email",
        },
        phone: {
          label: "Номер телeфона",
          placeholder: "Введите Ваш номер телeфона",
          error: "Пожалуйста, введите номер телeфона",
          errorFormat: "Пожалуйста, введите корректный номер телефона",
        },
        pass: {
          label: "Пароль",
          error: "Пожалуйста, введите пароль",
        },
        passConfirm: {
          label: "Повторите пароль",
          error: "Пожалуйста, введите пароль",
          valid: "Введенный вами пароль не совпадает!",
        },
        groups: {
          label: "Группа",
          placeholder: "Выберите группу пользователя",
          error: "Пожалуйста, выберите группу пользователя",
          errorFormat: "Пожалуйста, выберите группы пользователей"
        },
        active: "Активен",
        hidden: "Заблокирован",
        userVisibility: "Блокировка пользователя",
        btn: "Сохранить",
        updateSuccess: "Пользователь был успешно обновлен",
      },

      boost: {
        title: "Накрутить баланс",
        balance: "Текущий баланс:",
        amount: "Введите сумму:",
        info: "Сумма которая введена, будет добавлена к текущему балансу",
        btn: "Увеличить",
        boostSuccess: "Увеличение баланса прошло успешно",
        boostError: "Баланс не может быть увеличен. Попробуйте позже",
      },
    },
  },

  purchaseConfirmModalModal: {
    title: 'Подтвердите покупку',
    text: 'Вы покупаете документ \'{{ title }}\', который стоит {{ price }} {{ currency }}. Эта сумма будет снята с вашего баланса. Подтвердите покупку.',
    confirm: "Подтвердите",
    cancel: "Отмена",
  },
};
