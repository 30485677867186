import { useMutation } from "@tanstack/react-query";
import useAuth from "../../../../hooks/useAuth";
import { scanlyApi, SCANLY_API_ROUTES } from "../../../../api";

const useLogin = () => {
    const { setUser, setToken } = useAuth();
    return useMutation({
        mutationKey: ["login"],
        mutationFn: async ({ password, email }) => {
            try {
                const loginData = await scanlyApi.post(SCANLY_API_ROUTES.LOGIN, { password, email });
                localStorage.setItem("access_token", loginData.data.access_token);
                const user = await scanlyApi.get(SCANLY_API_ROUTES.USER);
                setUser(user.data.user_data);
                setToken(loginData.data.access_token);
                return user;
            } catch (err) {
                throw err;
            }
        }
    });
};

export default useLogin;