import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { InputUi } from "../../ui";
import { useSignUp, useLogin } from "./hooks";
import useMessage from "../../../hooks/useMessage";
import { IconEye, IconEyeSlash } from "../../../utils/Icons";

const Register = ({ close }) => {
  const basePath = "auth.register.";
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const [state, setState] = useState({});
  const signUpMutation = useSignUp();
  const loginMutation = useLogin();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const onChange = (evt) => {
    setState((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));
  };

  const successForm = () => {
    const { password, login, email } = state;
    signUpMutation.mutate(
      { password, login, email },
      {
        onError: (err) =>
          showErrorMessage(t(`apiErrors.${err.response.data.message}`)),
        onSuccess: (data) => {
          loginMutation.mutate(
            { password, email },
            {
              onSuccess: () => {
                form.resetFields();
                close();
                showSuccessMessage(t("auth.messages.successSignUpAndLogin"));
              },
            }
          );
        },
      }
    );
  };

  return (
    <div>
      <Form
        form={form}
        name="register"
        layout="vertical"
        onFinish={successForm}
        autoComplete="off"
        onChange={onChange}
      >
        <InputUi
          name="login"
          label={t(`${basePath}name.label`)}
          placeholder={t(`${basePath}name.placeholder`)}
          errorMess={t(`${basePath}name.error`)}
        />

        <InputUi
          name="email"
          type="email"
          label={t(`${basePath}email.label`)}
          placeholder={t(`${basePath}email.placeholder`)}
          moreRules={{
            type: "email",
            message: t(`${basePath}email.valid`),
          }}
          errorMess={t(`${basePath}email.error`)}
        />

        <InputUi
          name="password"
          type="password"
          label={t(`${basePath}pass.label`)}
          placeholder="*******"
          errorMess={t(`${basePath}pass.error`)}
        />

        <Form.Item
          name="confirm"
          label={t(`${basePath}passConfirm.label`)}
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: t(`${basePath}passConfirm.error`),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(t(`${basePath}passConfirm.valid`))
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="*******"
            name="confirm"
            iconRender={(visible) =>
              visible ? (
                <IconEyeSlash width="20" height="20" fill="#fff" />
              ) : (
                <IconEye width="20" height="20" fill="#fff" />
              )
            }
          />
        </Form.Item>

        <div className="btn-group mt-30">
          <div className="btn-persv">
            <Button
              htmlType="submit"
              loading={loginMutation.isPending || signUpMutation.isPending}
            >
              {t(`${basePath}btn`)}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Register;
