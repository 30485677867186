import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Checkbox, Col, Row } from "antd";
import useMessage from "../../hooks/useMessage";
import { IconEye, IconEyeSlash } from "../../utils/Icons";
import useUpdateProduct from "../../hooks/useUpdateProduct";
import { InputUi, ModalUi, InputNumberUi, SwitchUi } from "../ui";

const EditDocModal = ({ product, onSetClose, onSuccess }) => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.admin.docs.card.";

  const {
    name_en,
    name_ru,
    price,
    price_with_discount,
    section_name_en,
    section_name_ru,
    subsection_name_en,
    subsection_name_ru,
    id,
    active,
  } = product;

  const sale = Boolean(price_with_discount);

  const { showErrorMessage } = useMessage();

  const [state, setState] = useState({
    name_en,
    name_ru,
    price,
    price_with_discount,
    section_name_en,
    section_name_ru,
    subsection_name_en,
    subsection_name_ru,
    sale,
    active,
  });

  const onChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const onUpdateSuccess = () => {
    onSetClose();
    onSuccess();
  };

  const { isPending, mutate } = useUpdateProduct(id, onUpdateSuccess);
  const successForm = (e) => {
    const result = {};

    for (const key in state) {
      if (product[key] !== state[key]) {
        result[key] = state[key];
      }
    }

    if (!state.sale) {
      result.price_with_discount = null;
    }

    delete result.sale;
    mutate(result);
  };

  const errorForm = () => {
    showErrorMessage(t(`user.admin.docs.card.edit.notUpdated`));
  };

  return (
    <ModalUi
      showModal={Boolean(product)}
      onCancel={onSetClose}
      width={800}
      className={"modal-editdoc"}
      title={t(`${basePath}edit.editProduct`)}
    >
      <Form
        name="bam"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
        initialValues={{
          name_en,
          name_ru,
          price,
          price_with_discount,
          section_name_en,
          section_name_ru,
          subsection_name_en,
          subsection_name_ru,
          sale,
        }}
      >
        <Row>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="name_en"
              label={t(`${basePath}nameEng`)}
              defaultValue={product.name_en}
              onChange={(value) => onChange("name_en", value.target.value)}
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="name_ru"
              label={t(`${basePath}nameRu`)}
              defaultValue={product.name_ru}
              onChange={(value) => onChange("name_ru", value.target.value)}
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputNumberUi
              name="price"
              label={t(`${basePath}price`)}
              defaultValue={product.price}
              onChange={(value) => onChange("price", value?.toFixed(2))}
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>

          <Col xl={12} lg={12} md={24} sm={24} xs={24} className="sale">
            <InputNumberUi
              disabled={!state.sale}
              required={state.sale}
              name="price_with_discount"
              label={t(`${basePath}priceDiscount`)}
              defaultValue={product.price_with_discount}
              onChange={(value) => {
                onChange("price_with_discount", value?.toFixed(2));
              }}
              errorMess={t(`formMain.message.requiredField`)}
            />

            <Checkbox
              name="sale"
              defaultChecked={state.sale}
              onChange={(e) => onChange("sale", e.target.checked)}
            >
              {t(`${basePath}edit.discount`)}
            </Checkbox>
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="section_name_en"
              label={t(`${basePath}sectionEng`)}
              defaultValue={product.section_name_en}
              onChange={(value) =>
                onChange("section_name_en", value.target.value)
              }
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="section_name_ru"
              label={t(`${basePath}sectionRu`)}
              defaultValue={product.section_name_ru}
              onChange={(value) =>
                onChange("section_name_ru", value.target.value)
              }
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="subsection_name_en"
              label={t(`${basePath}subsectionEng`)}
              defaultValue={product.subsection_name_en}
              onChange={(value) =>
                onChange("subsection_name_en", value.target.value)
              }
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={12} lg={12} md={24} sm={24} xs={24}>
            <InputUi
              name="subsection_name_ru"
              label={t(`${basePath}subsectionRu`)}
              defaultValue={product.subsection_name_ru}
              onChange={(value) =>
                onChange("subsection_name_ru", value.target.value)
              }
              errorMess={t(`formMain.message.requiredField`)}
            />
          </Col>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <SwitchUi
              defaultChecked={active}
              name="active"
              label={t(`${basePath}productVisibility`)}
              beforeLabel={t(`${basePath}hidden`)}
              afterLabel={t(`${basePath}active`)}
              checkedIcon={<IconEye width="15" height="15" fill="#000" />}
              unCheckedIcon={
                <IconEyeSlash width="15" height="15" fill="#fff" />
              }
              onChange={(value) => onChange("active", value)}
            />
          </Col>
          <Col
            lg={24}
            md={24}
            sm={24}
            xs={24}
            className="d-flex justify-center"
          >
            <Button loading={isPending} type="dashed" htmlType="submit">
              {t(`${basePath}edit.save`)}
            </Button>
          </Col>
        </Row>
      </Form>
    </ModalUi>
  );
};

export default EditDocModal;
