import React from "react";
import { Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IconAlarmClock,
  IconAlfabank,
  IconAnglesRight,
  IconBank,
  IconBarcode,
  IconClipboardText,
  IconDocumentList,
  IconGazprom,
  IconIDcard,
  IconImage,
  IconInvoice,
  IconLeaf,
  IconPapersText,
  IconReceiptText,
  IconSberbank,
  IconScanQR,
  IconTinkoff,
  IconUserViewfinder,
} from "../../utils/Icons";

const { Text } = Typography;

const Other = () => {
  const { t } = useTranslation();
  const basePath = "home.itemList.other.";

  return (
    <div className="other-documents">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconDocumentList width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
          <div className="item">
            <div className="head">
              <span>
                <IconBank width="24" height="24" fill="#00c39a" />
              </span>
              {t(`${basePath}bank.title`)}
            </div>
            <Text>{t(`${basePath}bank.desc`)}</Text>
            <Row className="mt-30">
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconSberbank width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconAlfabank width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconTinkoff width="26" height="26" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconGazprom width="26" height="26" fill="#00c39a" />
                  </i>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-30 justify-center">
              <div className="btn-persv">
                <Link to="/bank" className="ant-btn ant-btn-default">
                  {t(`home.itemList.btn`)}
                  <IconAnglesRight width="15" height="15" fill="#fff" />
                </Link>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
          <div className="item soon">
            <div className="head">
              <span>
                <IconClipboardText width="24" height="24" fill="#00c39a" />
              </span>
              {t(`${basePath}doc.title`)}
            </div>
            <Text>{t(`${basePath}doc.desc`)}</Text>
            <Row className="mt-30">
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconIDcard width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconPapersText width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconInvoice width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconReceiptText width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-30 justify-center">
              <div className="btn-persv">
                <Link to="/bank" className="ant-btn ant-btn-default">
                  {t(`home.itemList.btn`)}
                  <IconAnglesRight width="15" height="15" fill="#fff" />
                </Link>
              </div>
            </div>
            <div className="coming">
              <Text>
                <IconAlarmClock width="26" height="26" fill="#00c39a" />
                Coming soon
              </Text>
            </div>
          </div>
        </Col>
        <Col xl={8} lg={24} md={24} sm={24} xs={24}>
          <div className="item soon">
            <div className="head">
              <span>
                <IconLeaf width="24" height="24" fill="#00c39a" />
              </span>
              {t(`${basePath}tools.title`)}
            </div>
            <Text>{t(`${basePath}tools.desc`)}</Text>
            <Row className="mt-30">
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconUserViewfinder width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconScanQR width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconImage width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                <div className="icon-element h-[55px] w-[55px]">
                  <span></span>
                  <i className="h-[52px] w-[52px]">
                    <IconBarcode width="24" height="24" fill="#00c39a" />
                  </i>
                </div>
              </Col>
            </Row>
            <div className="d-flex mt-30 justify-center">
              <div className="btn-persv">
                <Link to="/bank" className="ant-btn ant-btn-default">
                  {t(`home.itemList.btn`)}
                  <IconAnglesRight width="15" height="15" fill="#fff" />
                </Link>
              </div>
            </div>
            <div className="coming">
              <Text>
                <IconAlarmClock width="26" height="26" fill="#00c39a" />
                Coming soon
              </Text>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Other;
