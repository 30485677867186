import { Button } from "antd";
import { ModalUi } from "../ui";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";

const SignUpModal = ({ showModal, onCancel }) => {
  const { t } = useTranslation();
  const { setAuthShowModal } = useAuth();

  return (
    <ModalUi
      showModal={showModal}
      onCancel={onCancel}
      className={`modal-signup`}
      title={t("auth.signUpModal.title")}
    >
      <img src={require("../../assets/img/error.svg").default} alt="" />
      <p>{t("auth.signUpModal.beforeBuy")}</p>
      <div className="btn-group">
        <div className="btn-persv">
          <Button
            onClick={() => {
              setAuthShowModal(true);
              onCancel();
            }}
          >
            {t("auth.signUpModal.btn")}
          </Button>
        </div>
      </div>
    </ModalUi>
  );
};

export default SignUpModal;
