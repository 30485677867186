import React, { useState } from "react";
import TimePickerUi from "./TimePickerUi";
import { useTranslation } from "react-i18next";
import { Checkbox, Col, Form, Radio, Row, Select, Slider } from "antd";
import {
  IconAngleDownSquare,
  IconMobileNetwork,
  IconTimesSquare,
} from "../../../utils/Icons";

const HeaderPhoneFormUi = ({ type, setMobileValue }) => {
  const { t } = useTranslation();
  const basePath = "formMain.";
  const [connect, setConnect] = useState("");

  const isIOS = type === "ios";
  const colLength = isIOS ? 24 : 12;

  const handleRadioChange = (field, value, callback) => {
    setMobileValue(field, value);
    if (callback) callback(value);
  };

  return (
    <Row>
      <Col xl={12} lg={12} md={12} sm={12} xs={24}>
        <TimePickerUi
          name="time-phone"
          label={t(`${basePath}timePhone`)}
          placeholder="00:00"
          format="HH:mm"
          onChange={(e) => setMobileValue("timeScreen", e)}
          errorMess={t("formMain.message.requiredField")}
        />
      </Col>

      {isIOS && (
        <>
          <Col xl={12} lg={12} md={12} sm={12} xs={24}>
            <Form.Item label={t(`${basePath}additional.label`)}>
              <Select
                name="additional"
                placeholder={t(`${basePath}additional.placeholder`)}
                onChange={(e) => setMobileValue("additional", e)}
                suffixIcon={
                  <IconAngleDownSquare width="16" height="16" fill="#00c39a" />
                }
                options={[
                  { value: null, label: t(`${basePath}additional.none`) },
                  { value: "geo", label: t(`${basePath}additional.geo`) },
                  { value: "bother", label: t(`${basePath}additional.bother`) },
                ]}
              />
            </Form.Item>
          </Col>

          <Col xl={9} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label={t(`${basePath}mobNetwork`)}>
              <Radio.Group
                onChange={(e) =>
                  handleRadioChange("mobNetwork", e.target.value, setConnect)
                }
                defaultValue="1"
                className="icon-connect"
              >
                {[...Array(4).keys()].map((i) => (
                  <Radio.Button key={i} value={(i + 1).toString()}>
                    <IconMobileNetwork width="20" height="20" fill="#ababab" />
                  </Radio.Button>
                ))}
                <Radio.Button value="5">
                  <img
                    src={require("../../../assets/img/icon/airplane.png")}
                    alt=""
                  />
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xl={9} lg={12} md={12} sm={24} xs={24}>
            <Form.Item label={t(`${basePath}mobConnect`)}>
              <Radio.Group
                onChange={(e) => setMobileValue("mobConnect", e.target.value)}
                initialValues="none-connect"
                className="icon-connect"
              >
                {["E", "3G", "LTE", "WIFI", "none-connect"].map((value) => (
                  <Radio.Button
                    key={value}
                    value={value}
                    disabled={
                      connect === "5" &&
                      value !== "WIFI" &&
                      value !== "none-connect"
                    }
                  >
                    {value === "none-connect" ? (
                      <IconTimesSquare
                        width="20"
                        height="20"
                        fill="#ababab"
                        style={{ top: "5px" }}
                      />
                    ) : (
                      value
                    )}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>

          <Col xl={6} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label="eSim">
              <Checkbox
                className="mt-10"
                onChange={(e) => setMobileValue("eSim", e.target.checked)}
              >
                {t(`${basePath}eSim`)}
              </Checkbox>
            </Form.Item>
          </Col>
        </>
      )}

      <Col lg={colLength} md={colLength} sm={colLength} xs={24}>
        <Form.Item label={t(`${basePath}battery.label`)}>
          <Slider
            defaultValue={50}
            onChange={(value) => setMobileValue("battery", value)}
          />
          <Checkbox
            onChange={(e) => setMobileValue("charge", e.target.checked)}
          >
            {t(`${basePath}battery.charge`)}
          </Checkbox>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default HeaderPhoneFormUi;
