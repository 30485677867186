import React from "react";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ruRu from "antd/es/locale/ru_RU";
import Routes from "./router/Routes";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { LanguageProvider, AuthProvider, MessageProvider, DocumentsProvider, SearchProvider } from "./contexts";

const queryClient = new QueryClient();

dayjs.locale("ru");

let updateLocale = require("dayjs/plugin/updateLocale");
let relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(updateLocale);
dayjs.extend(relativeTime);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={ruRu}>
        <LanguageProvider>
          <AuthProvider>
            <MessageProvider>
              <DocumentsProvider>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
              </DocumentsProvider>
            </MessageProvider>
          </AuthProvider>
        </LanguageProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
