import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Col, Row, Tooltip } from "antd";
import { IconCheckSquare, IconTrash } from "../../../../utils/Icons";
import {
  DatePickerUi,
  InputNumberUi,
  InputUi,
  SelectUi,
} from "../../../../components/ui";

const DynamicForm = ({ transferList, setTransferList }) => {
  const { t } = useTranslation();
  const basePath = "wallets.form.trust.list.";

  const handleChange = (e, index) => {
    const { id, value } = e.target;
    const list = [...transferList];
    list[index][id] = value;
    setTransferList(list);
  };

  const handleRemove = (index) => {
    const updatedList = transferList.filter((_, i) => i !== index);
    setTransferList(updatedList);
  };

  const handleAdd = () => {
    setTransferList([...transferList, {}]);
  };

  return (
    <>
      {transferList.map((item, index) => (
        <div key={index} className={`transfer-list`}>
          <div className="item">
            <Row>
              <Col lg={12} md={12} sm={24} xs={24}>
                <DatePickerUi
                  id={"date"}
                  name={`date-${index}`}
                  label={t(`${basePath}date.label`)}
                  format={"MMM D YYYY"}
                  placeholder={t(`${basePath}date.plac`)}
                  onChange={(e) =>
                    handleChange({ target: { value: e, id: "date" } }, index)
                  }
                  errorMess={t(`formMain.message.requiredField`)}
                />
                {transferList.length !== 1 && index !== 0 && (
                  <Checkbox
                    id={"today"}
                    name={`today-${index}`}
                    className="check-box"
                    onChange={(e) =>
                      handleChange(
                        { target: { value: e.target.checked, id: "today" } },
                        index
                      )
                    }
                  >
                    {t(`${basePath}date.today`)}
                  </Checkbox>
                )}
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <InputNumberUi
                  id={"amount"}
                  name={`amount-${index}`}
                  label={t(`${basePath}amount`)}
                  placeholder={"100"}
                  value={item.amount}
                  onChange={(e) =>
                    handleChange({ target: { value: e, id: "amount" } }, index)
                  }
                  disabled={item.today}
                  errorMess={
                    !item.today
                      ? t(`formMain.message.requiredField`)
                      : undefined
                  }
                />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <InputUi
                  id={"wallet"}
                  name={`wallet-${index}`}
                  label={t(`${basePath}wallet`)}
                  placeholder={"TXR8ClnxhxEBvi7ZKmzb8LATLsRiroozTp"}
                  value={item.wallet}
                  onChange={(e) => handleChange(e, index)}
                  errorMess={t(`formMain.message.requiredField`)}
                />
              </Col>
              <Col lg={12} md={12} sm={24} xs={24}>
                <SelectUi
                  id={"type"}
                  name={`type-${index}`}
                  label={t(`${basePath}type.label`)}
                  placeholder={t(`${basePath}type.plac`)}
                  value={item.type}
                  onChange={(e) =>
                    handleChange({ target: { value: e, id: "type" } }, index)
                  }
                  options={[
                    {
                      value: "received",
                      label: t(`${basePath}type.elem.rece`),
                    },
                    { value: "sent", label: t(`${basePath}type.elem.sent`) },
                  ]}
                  errorMess={t(`formMain.message.requiredField`)}
                />
              </Col>
            </Row>
          </div>
          <div className="second-division">
            {transferList.length - 1 === index && (
              <Button type="button" onClick={handleAdd} className="btn-st">
                <IconCheckSquare width="20" height="20" fill="#00c39a" />
                {t(`${basePath}btn.add`)}
              </Button>
            )}
            {transferList.length !== 1 && (
              <Tooltip title={t(`${basePath}btn.remove`)}>
                <Button
                  type="button"
                  onClick={() => handleRemove(index)}
                  className="btn-remove"
                >
                  <IconTrash width="20" height="20" fill="#d94c48" />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default DynamicForm;
