import { useState, useContext } from "react";
import { Row } from "antd";
import DocCard from "./DocCard";
import { Layout } from "../../../components/Layout";
import AdminBar from "../../../components/AdminBar";
import { CollapseUi, SpinUi } from "../../../components/ui";
import EditDocModal from "../../../components/Modal/EditDocModal";
import { DocumentsContext } from "../../../contexts/DocumentsProvider";
import {
  IconAlfabank,
  IconBinance,
  IconBybit,
  IconExodus,
  IconHTX,
  IconOKX,
  IconSberbank,
  IconTrustWallet,
} from "../../../utils/Icons";

const subsections = [
  { name: "Binance", Icon: IconBinance },
  { name: "ByBit", Icon: IconBybit },
  { name: "OKX", Icon: IconOKX },
  { name: "HTX", Icon: IconHTX },
  { name: "Trust Wallet", Icon: IconTrustWallet },
  { name: "Exodus", Icon: IconExodus },
  { name: "Sberbank", Icon: IconSberbank },
  { name: "Alfa Bank", Icon: IconAlfabank },
];

const Docs = () => {
  const [product, setProduct] = useState(null);
  const { products, isLoading, isFetching, refetchProducts } =
    useContext(DocumentsContext);

  const onSetProduct = (product) => setProduct(product);
  const onSetClose = () => setProduct(null);

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout admin docs">
        <AdminBar />
        {(isLoading || isFetching) && <SpinUi size={54} />}

        <CollapseUi
          className={"border"}
          defaultActiveKey={["1"]}
          items={subsections.map(({ name, Icon }, index) => ({
            key: `${index + 1}`,
            label: (
              <>
                <Icon width="22" height="22" fill="#00c39a" /> {name}
              </>
            ),
            children: (
              <Row className="w-full">
                {products
                  ?.filter((item) => item.subsection_name_en === name)
                  .map((productProps) => (
                    <DocCard
                      key={`DocCard-${productProps.id}`}
                      onSetProduct={onSetProduct}
                      product={productProps}
                    />
                  ))}
              </Row>
            ),
          }))}
        />
      </div>

      {product && (
        <EditDocModal
          product={product}
          onSetClose={onSetClose}
          onSuccess={refetchProducts}
        />
      )}
    </Layout>
  );
};

export default Docs;
