import React from "react";
import { Alert } from "antd";
import {
  IconCheckSquare,
  IconInfoSquare,
  IconTimesSquare,
  IconTriangleExclamation,
} from "../../../utils/Icons";

const AlertUi = ({ type, text, ...props }) => {
  const Icon = (type) => {
    switch (type) {
      case "success":
        return <IconCheckSquare width="18" height="18" fill="#25ca7f" />;
      case "info":
        return <IconInfoSquare width="20" height="20" fill="#00a3e1" />;
      case "warning":
        return (
          <IconTriangleExclamation width="18" height="18" fill="#bcb727" />
        );
      case "error":
        return <IconTimesSquare width="18" height="18" fill="#d94c48" />;
      default:
        return type;
    }
  };

  return (
    <Alert message={text} type={type} showIcon icon={Icon(type)} {...props} />
  );
};

export default AlertUi;
