import React, { useContext, useState } from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import getDocName from "../../../utils/getDocTitle";
import { ALFA_ARRAY } from "../../../constants/BankArray";
import { SUBSECTIONS_ENUM } from "../../../enum/subsections";
import { CardUi, ModalUi, SpinUi } from "../../../components/ui";
import { IconApple, IconMoreHorizontal } from "../../../utils/Icons";
import { DocumentsContext } from "../../../contexts/DocumentsProvider";

function AlfaList() {
  // Translation
  const { t } = useTranslation();
  const basePath = "bank.";

  const [showModal, setShowModal] = useState(false);
  const [exchangeData, setExchangeData] = useState(ALFA_ARRAY[0]);

  const { getProductsBySubcategory, products, isLoading } =
    useContext(DocumentsContext);
  const subsectionProducts = getProductsBySubcategory(
    SUBSECTIONS_ENUM.AlfaBank,
    products
  );

  if (isLoading) {
    return <SpinUi />;
  }

  return (
    <>
      {subsectionProducts
        .filter((item) => item.active && !item.delete)
        .map((item, i) => (
          <Col
            lg={8}
            md={12}
            sm={24}
            xs={24}
            key={i}
            onClick={() => {
              setExchangeData(item);
              setShowModal(true);
            }}
          >
            <CardUi
              type="main"
              title={getDocName(item)}
              price={item.price}
              discountPrice={item.price_with_discount}
              icon={item.icon}
              preImg={item.preImg}
            />
          </Col>
        ))}

      <ModalUi
        title={getDocName(exchangeData)}
        showModal={showModal}
        setShowModal={() => {
          setShowModal(false);
        }}
      >
        <p>{t(`${basePath}modal.label`)}</p>
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Link
              to={`${exchangeData.modal.ios}/${exchangeData.api_id}`}
              className="system-item-modal"
            >
              <IconApple width="18" height="18" fill="#00c39a" />
              <h6>iOS</h6>
              <span>
                <IconMoreHorizontal width="24" height="24" fill="#2b2c2f" />
              </span>
            </Link>
          </Col>
        </Row>
      </ModalUi>
    </>
  );
}

export default AlfaList;
