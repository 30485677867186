import { useParams } from "react-router-dom";
import { Col, Row } from "antd";
import EditInfo from "./components/EditInfo";
import useUsers from "../../../hooks/useUsers";
import { IconUser } from "../../../utils/Icons";
import { Layout } from "../../../components/Layout";
import BoostBalance from "./components/BoostBalance";
import { SpinUi, TitlePageUi } from "../../../components/ui";

const UserPage = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useUsers({
    params: { id },
    settings: { cacheTime: 0 },
  });

  if (isLoading) {
    return (
      <div className="spin-loader-page">
        <SpinUi size="54" />
      </div>
    );
  }

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout admin edit-user">
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <TitlePageUi
              name={data?.data?.data[0].login}
              addonIcon={<IconUser width="24" height="24" fill="#00c39a" />}
              suffixIcon={null}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={16} lg={16} md={24} sm={24} xs={24}>
            <EditInfo data={data?.data?.data[0]} refetch={refetch} />
          </Col>
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <BoostBalance data={data?.data?.data[0]} refetch={refetch} />
          </Col>
        </Row>
      </div>
    </Layout>
  );
};

export default UserPage;
