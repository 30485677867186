import { useState, useEffect, useCallback } from "react";
import { Transfer } from "antd";
import { useTranslation } from "react-i18next";
import { IconAngleDownSquare } from "../../../utils/Icons";
import useCoinMarketRate from "../../../hooks/useCoinMarketRate";
import { CRYPTOCURRENCY_ARRAY } from "../../../constants/CryptocurrencyArray";

const COINS_ARRAY = CRYPTOCURRENCY_ARRAY.map(({ value }) =>
  value === "USDT" ? "USDC" : value
);

const TransferCoinsWithRate = ({ onTransferCoinWithRate, setData }) => {
  const { t } = useTranslation();
  const basePath = "formMain.transfer.";

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [dataSource, setDataSource] = useState(CRYPTOCURRENCY_ARRAY);

  const { data, isSuccess } = useCoinMarketRate({ coinIdArray: COINS_ARRAY });

  const onSuccess = useCallback(
    (data) => {
      const newDataSource = CRYPTOCURRENCY_ARRAY.map((crypto) => {
        const adjustedValue = crypto.value === "USDT" ? "USDC" : crypto.value;
        const matchedData = data.find(
          (item) => item.symbol.replace("USDT", "") === adjustedValue
        );

        return matchedData
          ? {
              ...crypto,
              price: matchedData.lastPrice,
              changePercent: matchedData.priceChangePercent,
            }
          : { ...crypto, price: "N/A", changePercent: "N/A" };
      });

      setDataSource(newDataSource);

      if (setData) {
        setData(newDataSource);
      }
    },
    [setData]
  );

  const onChangeTransfer = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  useEffect(() => {
    onTransferCoinWithRate(
      dataSource.filter((item) => targetKeys.includes(item.key))
    );
  }, [targetKeys, dataSource, onTransferCoinWithRate]);

  useEffect(() => {
    if (data && isSuccess) {
      onSuccess(data);
    }
  }, [isSuccess, data, onSuccess]);

  return (
    <Transfer
      dataSource={dataSource}
      titles={[t(`${basePath}all`), t(`${basePath}select`)]}
      targetKeys={targetKeys}
      selectedKeys={selectedKeys}
      onChange={onChangeTransfer}
      onSelectChange={onSelectChange}
      render={(item) => `${item.name}`}
      selectionsIcon={
        <IconAngleDownSquare width="20" height="20" fill="#ababab" />
      }
    />
  );
};

export default TransferCoinsWithRate;
