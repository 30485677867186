import React, { useEffect, useState } from "react";
import { Button, Col, Row, Typography, Form, Tooltip, Empty } from "antd";
import dayjs from "dayjs";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import useRefill from "../../hooks/useRefill";
import useMessage from "../../hooks/useMessage";
import { Layout } from "../../components/Layout";
import {
  InputNumberUi,
  SelectUi,
  ActionValue,
  ModalUi,
  AlertUi,
} from "../../components/ui";
import {
  IconDollar,
  IconCopy,
  IconTimesSquare,
  IconRotateSquare,
  IconCheckSquare,
} from "../../utils/Icons";

const Status = ({ type, record }) => {
  const { t } = useTranslation();
  let Icon = () => null;
  let txt;
  let fill;

  if (type === "waiting") {
    Icon = IconRotateSquare;
    fill = "#eae328";
    txt = t("user.refill.waiting");
  }
  if (type === "canceled") {
    Icon = IconTimesSquare;
    fill = "#d94c48";
    txt = record.fp_error_message;
  }
  if (type === "completed") {
    Icon = IconCheckSquare;
    fill = "#25ca7f";
    txt = t("user.refill.completed");
  }

  return (
    <Tooltip placement="top" title={txt}>
      <Icon width={24} height={24} fill={fill} />
    </Tooltip>
  );
};

const getStatus = (historyRecord) => {
  if (historyRecord.status === 1) {
    return "waiting";
  }

  return historyRecord.fp_status;
};

const { Title, Text } = Typography;

const Profile = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "user.refill.";

  const { user, mutateUser } = useAuth();
  const { data, isPending, mutate } = useRefill();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const [state, setState] = useState({
    currency: "usdt",
    system: "trc20",
  });

  const [showModal, setShowModal] = useState(false);

  const onChange = (name, value) => {
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    const interval = setInterval(() => mutateUser(), 10000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const successForm = () =>
    mutate(state, {
      onError: (err) => {
        const key = err.response?.data?.data?.message
          ? `apiErrors.${err.response.data.data.message}`
          : `apiErrors.${err.response.data.message}`;
        console.error(
          err.response?.data?.data?.message
            ? `apiErrors.${err.response.data.data.message}`
            : `apiErrors.${err.response.data.message}`
        );
        showErrorMessage(t(key.replace(":", "")));
      },
      onSuccess: () => {
        setShowModal(true);
        mutateUser();
      },
    });

  async function copyNodeTextToClipboard(nodeId) {
    try {
      const node = document.getElementById(nodeId);

      if (!node) {
        console.error("Element with id " + nodeId + " not found");
        return;
      }

      const text = node.textContent;
      await navigator.clipboard.writeText(text);

      showSuccessMessage(t("user.profil.walletCopied"));
    } catch (err) {
      showErrorMessage(t("user.profil.failedCopyWallet"));
      console.error(err);
    }
  }

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout refill">
        <Row className="refill-row">
          <Col xl={16} lg={24} md={24} sm={24} xs={24}>
            <div className="box mb-30">
              <Form
                name="bwd"
                layout="vertical"
                onFinish={successForm}
                onFinishFailed={() => {}}
                autoComplete="off"
              >
                <Row>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={4} className={"mb-30"}>
                      {t(`${basePath}title`)}
                    </Title>
                    <InputNumberUi
                      name={"amount"}
                      label={t(`${basePath}form.amount`)}
                      placeholder={"100"}
                      onChange={(value) => onChange("amount", value)}
                      errorMess={t(`formMain.message.requiredField`)}
                    />
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <SelectUi
                      name={"currency"}
                      label={t(`${basePath}form.system`)}
                      placeholder={t(`${basePath}form.system`)}
                      onChange={(value) => onChange("currency", value)}
                      options={[
                        {
                          value: "usdt",
                          label: "Tether (USDT)",
                        },
                        // {
                        //   value: "btc",
                        //   label: "Bitcoin (BTC)",
                        // },
                        // {
                        //   value: "eth",
                        //   label: "Ethereum (ETH)",
                        // },
                      ]}
                      errorMess={t(`formMain.message.requiredField`)}
                    />
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <SelectUi
                      name={"system"}
                      label={t(`${basePath}form.network`)}
                      placeholder={t(`${basePath}form.network`)}
                      onChange={(value) => onChange("system", value)}
                      options={[
                        {
                          value: "trc20",
                          label: "TRC20",
                        },
                        // {
                        //   value: "erc20",
                        //   label: "ERC20",
                        // },
                      ]}
                      errorMess={t(`formMain.message.requiredField`)}
                    />
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <AlertUi
                      type="info"
                      text={t(`${basePath}form.info`)}
                      className="mb-30 mt-20"
                    />
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Button
                      htmlType="submit"
                      className="m-auto"
                      loading={isPending}
                    >
                      {t(`${basePath}form.btn`)}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>

          <Col xl={8} lg={24} md={24} sm={24} xs={24}>
            <div className="box refill-history mb-30">
              <Title level={4} className={"mb-30"}>
                {t(`${basePath}lastOperations.title`)}
              </Title>
              <div className="items">
                {user.payment_history && user.payment_history.length > 0 ? (
                  user.payment_history
                    .toReversed()
                    .filter(
                      (item) => Boolean(item.type) && item.type === "deposit"
                    )
                    .map((item, i) => (
                      <div className={`card-item second`} key={i}>
                        <div className="icon">
                          <span></span>
                          <i>
                            <IconDollar width="22" height="22" fill="#00c39a" />
                          </i>
                        </div>
                        <div className="info">
                          <div className="info__block">
                            <span>
                              {t(`${basePath}lastOperations.amount`)}{" "}
                              <s>
                                {item.summa >= 1
                                  ? Number(item.summa).toFixed(2)
                                  : item.summa}
                              </s>
                            </span>
                            <span>
                              {t(`${basePath}lastOperations.system`)}{" "}
                              <s>
                                {item.currency} {item.system}
                              </s>
                            </span>
                          </div>
                          <div className="info__block">
                            <span>
                              {t(`${basePath}lastOperations.date`)}
                              <s>
                                {dayjs(item.createdAt).format("DD.MM.YYYY")}
                              </s>
                            </span>
                            <span className="status">
                              <span>
                                {t(`${basePath}lastOperations.status`)}:
                              </span>
                              {<Status type={getStatus(item)} record={item} />}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                ) : (
                  <Empty className="big" />
                )}
              </div>
            </div>
          </Col>
        </Row>

        <ModalUi
          title={t(`${basePath}modal.title`)}
          showModal={showModal}
          width={800}
          className={"modal-qrcode"}
          setShowModal={() => {
            setShowModal(false);
          }}
        >
          {!isPending && data?.data?.data?.wallet && (
            <div className="content">
              <div className="qr-code">
                <Text>{t(`${basePath}modal.qr`)}</Text>
                <QRCode
                  value={data?.data?.data?.wallet}
                  bgColor="#000"
                  fgColor="#FFF"
                />
              </div>
              <div className="result">
                <p>
                  {t(`${basePath}modal.transfer`)}{" "}
                  <span>
                    {state.amount} {state.currency}
                  </span>{" "}
                </p>
                <p>{t(`${basePath}modal.wallet`)}</p>
                <ActionValue
                  action={() =>
                    copyNodeTextToClipboard(data?.data?.data?.wallet)
                  }
                  value={data?.data?.data?.wallet}
                  btnTxt={t("user.profil.copyWallet")}
                  valueProps={{ id: data?.data?.data?.wallet }}
                  icon={<IconCopy width="24" height="24" fill="#00c39a" />}
                />
                <AlertUi type="info" text={t(`${basePath}modal.info`)} />
              </div>
            </div>
          )}
        </ModalUi>
      </div>
    </Layout>
  );
};

export default Profile;
