import { useEffect, useState, useContext, useMemo } from "react";
import { SpinUi } from "../../../components/ui";
import { Layout } from "../../../components/Layout";
import AdminBar from "../../../components/AdminBar";
import PurchasesList from "./components/PurchasesList";
import usePurchaseHistory from "../../../hooks/usePurchaseHistory";
import getDocsEnumWithIDs from "../../../utils/getDocsEnumWithIDs";
import { DocumentsContext } from "../../../contexts/DocumentsProvider";

const Purchases = () => {
  const [tableData, setTableData] = useState([]);
  const [init, setInit] = useState(true);
  const [params, setParams] = useState({
    page_size: 10,
    page: 1,
  });

  const { data, isLoading, status, refetch, fetchStatus } = usePurchaseHistory({
    params,
  });

  const onSuccess = () => {
    setTableData(data?.data?.data);
    setInit(false);
  };

  useEffect(() => {
    if (status === "success") {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, params.page, fetchStatus]);

  const { products, isLoading: isProductsLoading } =
    useContext(DocumentsContext);
  const DOCS_ENUM = useMemo(() => getDocsEnumWithIDs(products), [products]);
  const tableDataFinish = tableData.map((item) => ({
    ...item,
    doc: { ...DOCS_ENUM[item.product_id] },
  }));

  return (
    <Layout section={"user"} exact={false}>
      <div className="layout admin users-list">
        <AdminBar />
        {isProductsLoading || (init && isLoading) ? (
          <SpinUi />
        ) : (
          <PurchasesList
            isLoading={isLoading}
            data={tableDataFinish}
            params={params}
            setParams={setParams}
            total={data?.data.total}
            refetch={refetch}
          />
        )}
      </div>
    </Layout>
  );
};
export default Purchases;
