import dayjs from "dayjs";
import { Button } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { ModalUi, SpinUi } from "../ui";
import useUsers from "../../hooks/useUsers";
import useMessage from "../../hooks/useMessage";
import getDocName from "../../utils/getDocTitle";
import useEditPurchase from "../../hooks/useEditPurchase";

const PurchaseEditPriseModal = ({
  showModal,
  onCancel,
  purchase,
  refetch,
  value,
  setEditPurchasePrise,
}) => {
  const { t } = useTranslation();
  const { showErrorMessage, showSuccessMessage } = useMessage();

  const { data, isLoading } = useUsers({
    params: { id: purchase?.user_id },
    settings: { cacheTime: 0 },
  });

  const title = getDocName(purchase?.doc);
  const basePath = "user.admin.purchaseList.table.";

  const user = data?.data?.data[0]?.login;

  const onSuccess = () => {
    setEditPurchasePrise(null);
    refetch();
    onCancel();
    showSuccessMessage(t(`${basePath}modal.priceEditSuccess`));
  };

  const onError = () => {
    showErrorMessage(t(`${basePath}modal.priceEditError`));
  };

  const { mutate } = useEditPurchase({
    onSuccess,
    onError,
  });

  const onConfirm = () => {
    mutate({ summa: value, id: purchase.id });
  };

  const createdAt = purchase?.createdAt;

  return (
    <ModalUi
      showModal={showModal}
      onCancel={onCancel}
      title={t(`${basePath}modal.titleEdit`)}
    >
      {isLoading ? (
        <div className="d-flex justify-center">
          <SpinUi size={54} />
        </div>
      ) : (
        <p className="delete-purchase-msg">
          <Trans
            values={{
              user,
              time: dayjs(createdAt).format("YYYY-MM-DD HH:mm:ss"),
              title,
              from: `${purchase.summa} USDT`,
              to: `${value} USDT`,
            }}
            i18nKey={`${basePath}modal.textEdit`}
          />
        </p>
      )}

      <div className="d-flex justify-center gap-[15] w-100">
        <Button type="dashed" className="cancel" onClick={onCancel}>
          {t("purchaseConfirmModalModal.cancel")}
        </Button>

        <Button type="dashed" disabled={isLoading} onClick={onConfirm}>
          {t("purchaseConfirmModalModal.confirm")}
        </Button>
      </div>
    </ModalUi>
  );
};

export default PurchaseEditPriseModal;
