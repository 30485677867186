import React, { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import AlfaList from "./Alfa/AlfaList";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Layout } from "../../components/Layout";
import SberbankList from "./Sberbank/SberbankList";
import { IconAlfabank, IconBank, IconSberbank } from "../../utils/Icons";

const Bank = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "bank.";
  
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState("1");

  useEffect(() => {
    if (location.state && location.state.tab) {
      setActiveTabKey(location.state.tab);
    }
  }, [location.state]);

  return (
    <Layout section="gen-page">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <div className="title-section">
            <span>
              <IconBank width="24" height="24" fill="#00c39a" />
            </span>
            <h2>{t(`${basePath}title`)}</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Tabs
            activeKey={activeTabKey}
            onChange={setActiveTabKey}
            type="card"
            className="big-size"
          >
            <Tabs.TabPane
              tab={
                <>
                  <IconSberbank width="18" height="18" fill="#ababab" />
                  {t(`${basePath}form.sberbank.title`)}
                </>
              }
              key="1"
            >
              <Row>
                <SberbankList />
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <>
                  <IconAlfabank width="18" height="18" fill="#ababab" />
                  {t(`${basePath}form.alfa.title`)}
                </>
              }
              key="2"
            >
              <Row>
                <AlfaList />
              </Row>
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    </Layout>
  );
};

export default Bank;
