export const TRANSLATIONS_EN = {
  shared: {
    edit: "Edit",
    save: "Save",
    cancel: "Cancel",
    restore: "Restore",
    delete: "Delete",
    filter: "Filter",
    reset: "Reset",
  },

  navBar: {
    auth: "Authorization",
    lang: {
      title: "Language",
      "en-US": "English",
      "ru-RU": "Russian",
    },
    welcome: "Welcome",
    dropDown: {
      profile: "Profile",
      logout: "Logout",
    },
  },

  sideBar: {
    menu: {
      profil: "Home",
      refill: "Refill",
      history: "Purchase History",
      document: "Documents",
      ref: "Referral program",
      setting: "Settings",
      admin: "Admin panel",
      logout: "Log out",
    },
  },

  userNavBar: {
    search: "Search documents",
    document: "Documents",
    history: "Purchase History",
    lang: {
      title: "Language",
      "en-US": "English",
      "ru-RU": "Russian",
    },
    menu: {
      profil: "Home",
      refill: "Refill",
      setting: "Settings",
      logout: "Log out",
    },
  },

  adminBar: {
    menu: {
      main: "Main",
      docs: "Documents",
      users: "Users",
      purchases: "Purchases",
    }
  },

  apiErrors: {
    "User or password incorrect": "User or password incorrect",
    "User not found": "User not found",
    "This email is already associated with another account":
      "This email is already associated with another account",
    refillError:
      "Failed to top up your account. Please try again.",
    "Amount less than allowed": "Amount less than allowed",
    "Required fields for this method amount, currency, system": "Required fields for this method: amount, currency, system",
    "User has insufficient balance for requested action": "Your balance is insufficient to make a purchase",
    'User not active': 'User is blocked. Contact the technical department!'
  },

  auth: {
    title: "Authorization",
    login: {
      title: "Log in",
      name: {
        label: "Login",
        placeholder: "Enter your login",
        error: "Please enter your login",
      },
      pass: {
        label: "Password",
        error: "Please enter your password",
      },
      btn: "Log in",
    },

    register: {
      title: "Registration",
      name: {
        label: "Login",
        placeholder: "Enter your login",
        error: "Please enter your login",
      },
      phone: {
        label: "Phone",
        placeholder: "Enter your phone",
        error: "Please enter your phone",
        errorFormat: "Please write in format +38 093 123 45 67"
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
        error: "Please enter your email",
        valid: "Please enter a valid email address",
      },
      pass: {
        label: "Password",
        error: "Please enter your password",
      },
      passConfirm: {
        label: "Repeat the password",
        error: "Please enter your password",
        valid: "The password you entered does not match!",
      },
      btn: "Sign up",
    },

    messages: {
      successSignUpAndLogin: "You have successfully registered and logged in",
      successLogin: "You have successfully logged in",
    },

    signUpModal: {
      title: "Error",
      beforeBuy: 'Before you buy this screenshot, please register and top up your balance.',
      btn: 'Sign up',
    },
  },

  breadcrumb: {
    title: "Navigation:",
    list: {
      home: "Home",
      sections: {
        exchange: "Cryptocurrency Exchanges",
        wallets: "Crypto Wallets",
        bank: {
          title: "Banks",
          subSections: {
            sberbank: "Sberbank",
            alfa: "Alfa Bank",
          }
        },
        allDocs: "All documents",
      },
    }
  },

  home: {
    banner: {
      desc: {
        first:
          "An online tool for creating High Quality images. You can create screenshots of withdrawals, deposits, transactions in just 2 minutes.",
        second:
          "You are responsible for the lawful use of the service. By using the service, you must be aware of local, state and federal laws in your jurisdiction and be solely responsible for your actions.",
      },
      btn: "Go to Tool",
    },

    itemList: {
      exchanges: {
        title: "Cryptocurrency Exchanges",
        descFirst:
          "A unique service for generating personalized screenshots! This category gives you the opportunity to customize and create screenshots yourself, reflecting exactly those data and aspects of trading platforms that you are interested in.",
        descSecond:
          "Unlimited customization options make this category an ideal tool for those looking for a convenient way to share their experiences, educate others, or simply create memorable visual reports of their trading successes. Let's share unique moments and insights with the world of cryptocurrency trading!",
      },
      wallet: {
        title: "Crypto Wallets",
        descFirst:
          "An innovative service for generating personalized screenshots for your wallets! This category gives you a unique opportunity to customize and create your own screenshots showing the details and transactions you want to share or save.",
        descSecond:
          "Choose any parameters you want, from visual design to transaction details. You can change amounts, asset names, dates and more to create the perfect image for your virtual wallet. This service is designed to give you complete control over the screenshots you create, providing the highest level of personalization.",
      },
      other: {
        title: "Various documents",
        bank: {
          title: "Banks",
          desc: "The Banking category provides users with a dynamic platform to simulate and create various screenshots related to banking activities. Users can create realistic images of transactions, transfers, deposits and other financial transactions by entering customizable data.",
        },
        doc: {
          title: "Documents",
          desc: "The Documents category offers users a versatile platform for creating realistic screenshots of various identity documents. From passports and ID cards to certificates and driver's licenses, users can enter any required data and create customized visual representations.",
        },
        tools: {
          title: "Tools",
          desc: "The Tools category is a versatile set of tools for creating screenshots, offering features such as barcodes, QR codes, people's faces, etc. Users can enter and customize data according to their requirements, allowing them to create personalized and realistic images.",
        },
      },
      btn: "Read more",
    },

    advantages: {
      items: [
        {
          icon: "flex",
          title: "Flexibility",
        },
        {
          icon: "cont",
          title: "Control",
        },
        {
          icon: "auto",
          title: "Automation",
        },
        {
          icon: "conv",
          title: "Convenience",
        },
        {
          icon: "fast",
          title: "Quickly",
        },
        {
          icon: "supp",
          title: "Support",
        },
      ],
    },

    works: {
      title: "How it works",
      item1: {
        title: "Registration",
        desc: "Create an account to get started with the service.",
      },
      item2: {
        title: "Replenishment",
        desc: "Deposit funds to your balance to access paid features.",
      },
      item3: {
        title: "Document selection",
        desc: "Select the desired document from the available options.",
      },
      item4: {
        title: "Document generation",
        desc: "Customize and get the finished document in the desired format.",
      },
    },
  },

  formMain: {
    title: "Fill in the information",
    price: "Price:",
    system: "Operating System:",
    theme: {
      title: "Theme design:",
      dark: "Dark",
      light: "Light",
    },

    formCollapse: {
      phone: "Phone Setup",
      content: "Content customization",
    },

    timePhone: "Time on the phone",
    mobNetwork: "Cellular communication",
    mobConnect: "Internet",
    battery: {
      label: "Battery charge",
      charge: "On a charge",
    },
    additional: {
      label: "Additional elements",
      placeholder: "Select additional items",
      none: "Disable",
      geo: "Geoposition",
      bother: "Don't bother",
    },
    eSim: "Activate eSim",

    transfer: {
      all: "Currencies",
      select: "Selected currencies",
    },

    btn: {
      png: "Download PNG",
      jpeg: "Download JPEG",
    },

    message: {
      success: "File successfully generated, wait for download!",
      error: "File not generated, try again!",
      requiredField: "This field is required!",
    },
  },

  card: {
    price: 'Price',
    os: "OS",
  },

  exchange: {
    title: "Cryptocurrency Exchanges",
    modal: {
      label: "Select the operating system",
    },

    form: {
      binance: {
        timeMail: "Time the message arrives in the mail",
        timeArrival: "Date and time of withdrawal request creation",
        amount: "Amount",
        currency: "Currency",
        network: "Network",
        wallet: "Wallet",
        pnl: "PnL for today",
        commission: "Network Commission",
        transaction: "Transaction ID",
        averCost: "Average cost",
        coinCount: "Enter the amount of currency",
        timeOperation: "Transaction date and time",
        balance: "Balances",
        selectCoin: "Select currencies",
      },

      bybit: {
        assets: {
          sport: "Spot",
          general: "Total assets",
          financing: "Financing",
          directives: "Derivatives",
        },

        currency: "Currency",
        wallet: "Wallet",
        network: "Network type",
        amount: "Amount",
        commission: "Commission",
        address: "Deposit address",
        account: "Deposit account",
        hash: "Transaction hash (TXID)",
        timeOperation: "Transaction date and time",
        coinCount: "Enter the amount of currency",
        timeMail: "Time the message arrives in the mail",
        depositAmount: "Deposit amount",
        timeArrival: "Date and time of withdrawal request creation",
        balance: "Balances",
        selectCoin: "Select currencies",
      },

      okx: {
        time: "Time",
        amount: "Amount",
        currency: "Currency",
        fee: "Commission",
        blockchain: "Blockchain",
        withdrawalID: "Output ID",
        reference: "Reference No.",
        addressDomain: "Address / domain",
        transactionID: "Transaction ID",
        timeOperation: "Transaction date and time",
        timeMail: "Time the message arrives in the mail",
        balance: "Balances",
        selectCoin: "Select currencies",
        coinCount: "Enter the amount of currency",
      },

      htx: {
        fee: "Commission",
        network: "Network type",
        wallet: "Output address",
        currency: "Currency",
        hash: "Transaction hash (TXID)",
        depositAmount: "Deposit amount",
        withdrawalAmount: "Withdrawal amount",
        pnlUsd: "PnL in ($)",
        pnlRate: "PnL in (%)",
        generalPnlUsd: "PnL total in ($)",
        generalPnlRate: "PnL total in (%)",
        timeOperation: "Transaction date and time",
        timeMail: "Time the message arrives in the mail",
        balance: "Balances",
        selectCoin: "Select currencies",
        coinCount: "Enter the amount of currency",
      },
    },
  },

  wallets: {
    title: "Crypto Wallets",
    modal: {
      label: "Select the operating system",
      currency: {
        label: "Select currency",
      },
    },

    form: {
      trust: {
        time: "Time",
        wallet: "Recipient",
        currency: "Currency",
        commission: "Commission",
        amount: "Enter the total amount",
        amountTransf: "Enter the amount of the transfer",
        balance: "Balances",
        selectCoin: "Select currencies",
        coinCount: "Enter the amount of currency",
        date: {
          label: "Date",
          plac: "Jan. 1 2023",
        },

        nameWallet: {
          label: "Wallet name",
          placeholder: "Basic Wallet",
        },

        status: {
          label: "Transaction Status",
          plac: "Select the status of the operation",
          elem: {
            copm: "Completed",
            pend: "In processing",
            fail: "Unfortunate",
          },
        },

        operation: {
          label: "Type of operation",
          plac: "Select the type of operation",
          elem: {
            rece: "Recipient",
            sent: "Sender",
            receSecond: "Received",
            sentSecond: "Posted",
          },
        },

        list: {
          title: "Transactions:",
          amount: "Amount",
          wallet: "Wallet",
          date: {
            label: "Date",
            plac: "Jan. 1 2023",
            today: "On the same date",
          },

          type: {
            label: "Type of operation",
            plac: "Select the type of operation",
            elem: {
              rece: "Received",
              sent: "Posted",
            },
          },

          btn: {
            add: "Add transaction",
            remove: "Delete transaction",
          },
        },
      },

      exodus: {
        amount: "Amount | Currency",
        amountCoin: "Quantity of coins",
        currency: "Currency",
        network: "Network",
        noNet: 'No network',
        fee: "Fee",
        wallet: "Wallet",
        notes: "Personal notes",
        date: "Transaction date",
        txid: "Transaction ID",
        amountNow: "Amount (Now)",
        amountOld: "Amount (At the time of transaction)",
        infoAmountOld:
          "The amount is specified at the moment when the transaction was made, if the transaction was made today, the field is not active.",
      },
    },
  },

  bank: {
    title: "Banks",
    modal: {
      label: "Select the operating system",
      currency: {
        label: "Select currency",
      },
    },

    form: {
      sberbank: {
        title: 'Sberbank',
        cardholder: "Cardholder's name",
        balance: "Balance on card",
        fullName: "Recipient's full name",
        sender: "Sender",
        fullDate: "Date and time",
        balanceBill: "Account balance",
        amountTransfer: "Transfer amount",
        recipientCard: "Recipient's card number",
        lastCard: "Last 4 digits of the card",
        lastBill: "Last 4 digits of the account",
        lastCardDebit: "Last 4 digits of the debit card",
        lastCardEnroll: "Last 4 digits of the credit card",
      },

      tinkoff: {
        title: 'Tinkoff Bank',
      },

      alfa: {
        title: 'Alfa Bank',
        fullDate: "Date and time of payment",
        balance: "Balance on the map",
        senderСard: "Sender's card",
        cardholder: "Cardholder's name",
        lastCard: "Last 4 digits of the card",
        lastBill: "Last 4 digits of the account",

        operation: {
          label: "Type of translation",
          type: {
            one: "Translation Type #1",
            oneValue: "type1",
            two: "Translation Type #2",
            twoValue: "type2",
            three: "Translation Type #3",
            threeValue: "type3",
          },
        },

        cards: {
          title: "Cards",
          type: "Card type",
          lastCard: "Last 4 digits of the card",

          btn: {
            add: "Add card",
            remove: "Delete card",
          },
        },
      },

      gazprom: {
        title: 'Gazprombank',
      },
    },
  },

  user: {
    profil: {
      welcome: {
        morning: "Good Morning",
        afternoon: "Good Afternoon",
        evening: "Good Evening",
      },
      balance: "Balance",
      refill: "Refill",
      quantityPurchases: "Quantity of purchases",
      top: "Top 3 documents",
      propose: {
        title: "Propose a document",
        btn: "Propose",
      },
      lastPurchases: "Latest purchases",
      modal: {
        btn: "Download",
      },
      copyWallet: "Copy Wallet",
      walletCopied: "Wallet copied to clipboard",
      failedCopyWallet: "Failed to copy wallet to clipboard",
    },

    history: {
      title: "Purchase history",
      modal: {
        btn: "Download",
      },
      lastOperations: "Recent operations",
    },

    refill: {
      waiting: "The request has been sent to the payment system. We are waiting for an answer.",
      completed: "Payment successful",
      title: "Refill",
      form: {
        amount: "Enter Amount",
        system: "Select System",
        network: "Select Network",
        btn: "Refill",
        info: "The application is processed 10 - 20 minutes after the actual crediting of funds (set of 2 confirmations(s) and crediting to the exchange account).",
      },
      modal: {
        title: 'Transfer',
        qr: 'QR-code:',
        transfer: 'Transfer the amount indicated -',
        wallet: 'To these requisites:',
        info: 'Make sure that the recipient\'s details are correct!',
      },
      lastOperations: {
        title: "Refill history",
        amount: "Amount:",
        system: "System:",
        date: "Date:",
        status: 'Status',
      },
    },

    settings: {
      title: "Edit profile",
      name: {
        label: "Login",
        placeholder: "Enter your login",
        error: "Please enter your login",
      },
      email: {
        label: "Email",
        placeholder: "Enter your email",
        error: "Please enter your email",
        valid: "Please enter a valid email address",
      },
      phone: {
        label: "Phone number",
        placeholder: "Enter your phone number",
        error: "Please enter your phone number",
      },
      pass: {
        label: "Password",
        error: "Please enter your password",
      },
      passConfirm: {
        label: "Repeat the password",
        error: "Please enter your password",
        valid: "The password you entered does not match!",
      },
      btn: "Save",
      updateSuccess: "Your profile was updated successfully",
    },

    admin: {
      docs: {
        card: {
          nameRu: "Product Name (RU):",
          nameEng: 'Product Name (ENG):',
          price: "Price:",
          priceDiscount: "Price (Discount):",
          sectionRu: "Section (RU):",
          sectionEng: "Section (ENG):",
          subsectionRu: 'Subsection (RU):',
          subsectionEng: "Subsection (ENG):",
          btn: "Edit",
          productVisibility: "Product visibility",
          active: "Shown",
          hidden: "Hidden",
          discountNone: "Absent",


          edit: {
            editProduct: "Edit Product",
            save: "Save",
            discount: "Activate discount",
            updated: "Product was updated",
            notUpdated: "Product was not updated",
          }
        }
      },

      usersList: {
        table: {
          title: "Users",
          search: "User search",
          login: "Login",
          balance: "Balance",
          actions: {
            title: "Actions",
            tooltip: "Edit",
          },
        },
      },

      purchaseList: {
        table: {
          title: "Document",
          price: "Price",
          deleted: 'Deleted',
          createdAt: 'Created At',
          actions: "Actions",
          user: "User",
          restore: "Restore document",
          delete: "Delete document",
          deleteYes: 'Yes',
          deleteNo: 'No',
          modal: {
            title: "Confirm Delete Purchase",
            titleRestore: "Confirm Restore Purchase",
            titleEdit: 'Confirm price change',
            text: "User: <strong>{{ user }}</strong> <br> Date: <strong>{{ time }}</strong> <br> Document: <strong>{{ title }}</strong> <br><br> The purchase will be deleted after confirmation. Please confirm your action",
            textRestore: "User: <strong>{{ user }}</strong> <br> Date: <strong>{{ time }}</strong> <br> Document: <strong>{{ title }}</strong> <br><br> The purchase will be restored upon confirmation. Please confirm your action",
            textEdit: "User: <strong>{{ user }}</strong> <br> Date: <strong>{{ time }}</strong> <br> Document: <strong>{{ title }}</strong> <br> Price change from <strong>{{ from }}</strong> to <strong>{{ to }}</strong>. <br><br> Please confirm your action",
            deleteSuccess: "Purchase successfully deleted",
            deleteError: "Purchase Delete Error",
            restoreSuccess: "Purchase successfully restored",
            restoreError: "Purchase Restore Error",
            priceEditSuccess: "Purchase prise successfully edited",
            priceEditError: "Purchase prise edit error",
          }
        }
      },

      editUser: {
        title: "Edit profile",
        name: {
          label: "Login",
          placeholder: "Enter your login",
          error: "Please enter your login",
        },
        email: {
          label: "Email",
          placeholder: "Enter your email",
          error: "Please enter your email",
          valid: "Please enter a valid email address",
        },
        phone: {
          label: "Phone number",
          placeholder: "Enter your phone number",
          error: "Please enter your phone number",
          errorFormat: "Please enter the correct phone number",
        },
        pass: {
          label: "Password",
          error: "Please enter your password",
        },
        passConfirm: {
          label: "Repeat the password",
          error: "Please enter your password",
          valid: "The password you entered does not match!",
        },
        groups: {
          label: "Groups",
          placeholder: "Enter your groups",
          error: "Please select user groups",
          errorFormat: "Please select user groups"
        },
        active: "Active",
        hidden: "Blocked",
        userVisibility: "User Lockout",
        btn: "Save",
        updateSuccess: "User was successfully updated",
      },

      boost: {
        title: "Tilt the balance",
        balance: "Current balance:",
        amount: "Enter the amount:",
        info: "The amount that is entered will be added to the current balance",
        btn: "Boost",
        boostSuccess: "Balance increase was successful",
        boostError: "Balance cannot be increased. Please try later",
      },
    }
  },

  purchaseConfirmModalModal: {
    title: 'Confirm your purchase',
    text: 'You buy a document \'{{ title }}\' that costs {{ price }} {{ currency }}. This amount will be withdrawn from your balance. Confirm your purchase.',
    confirm: "Confirm",
    cancel: "Cancel",
  },
};
