import ExchangeBinanceWalletMain from "../pages/Exchange/Binance/Wallet/Main/Result";
import ExchangeBinanceWalletDeposit from "../pages/Exchange/Binance/Wallet/Deposit/Result";
import ExchangeBinanceWalletWithdrawal from "../pages/Exchange/Binance/Wallet/Withdrawal/Result";
import ExchangeBinanceMailDeposit from "../pages/Exchange/Binance/Mail/Deposit/Result";
import ExchangeBinanceMailWithdrawal from "../pages/Exchange/Binance/Mail/Withdrawal/Result";

import ExchangeByBitWalletMain from "../pages/Exchange/Bybit/Wallet/Main/Result";
import ExchangeByBitWalletAssets from "../pages/Exchange/Bybit/Wallet/Assets/Result";
import ExchangeByBitWalletDeposit from "../pages/Exchange/Bybit/Wallet/Deposit/Result";
import ExchangeByBitWalletWithdrawal from "../pages/Exchange/Bybit/Wallet/Withdrawal/Result";
import ExchangeByBitMailDeposit from "../pages/Exchange/Bybit/Mail/Deposit/Result";
import ExchangeByBitMailWithdrawal from "../pages/Exchange/Bybit/Mail/Withdrawal/Result";

import ExchangeOKXWalletBalance from "../pages/Exchange/OKX/Wallet/Balance/Result";
import ExchangeOKXWalletDeposit from "../pages/Exchange/OKX/Wallet/Deposit/Result";
import ExchangeOKXWalletWithdrawal from "../pages/Exchange/OKX/Wallet/Withdrawal/Result";
import ExchangeOKXMailDeposit from "../pages/Exchange/OKX/Mail/Deposit/Result";
import ExchangeOKXMailWithdrawal from "../pages/Exchange/OKX/Mail/Withdrawal/Result";

import ExchangeHTXWalletMain from "../pages/Exchange/HTX/Wallet/Main/Result";
import ExchangeHTXWalletDeposit from "../pages/Exchange/HTX/Wallet/Deposit/Result";
import ExchangeHTXWalletWithdrawal from "../pages/Exchange/HTX/Wallet/Withdrawal/Result";
import ExchangeHTXMailDeposit from "../pages/Exchange/HTX/Mail/Deposit/Result";
import ExchangeHTXMailWithdrawal from "../pages/Exchange/HTX/Mail/Withdrawal/Result";

import WalletsTrustMain from "../pages/Wallets/TrustWallet/Main/Result";
import WalletsTrustCoin from "../pages/Wallets/TrustWallet/Coin/Result";
import WalletsTrustTransfer from "../pages/Wallets/TrustWallet/Transfer/Result";
import WalletsTrustHistory from "../pages/Wallets/TrustWallet/History/Result";
import WalletsExodusCoin from "../pages/Wallets/Exodus/Coin/Result";
import WalletsExodusReceived from "../pages/Wallets/Exodus/Received/Result";
import WalletsExodusSent from "../pages/Wallets/Exodus/Sent/Result";

import BankSberbankMain from "../pages/Bank/Sberbank/Main/Result";
import BankSberbankCard from "../pages/Bank/Sberbank/Card/Result";
import BankSberbankBill from "../pages/Bank/Sberbank/Bill/Result";
import BankSberbankDelivered from "../pages/Bank/Sberbank/Delivered/Result";
import BankSberbankCompleted from "../pages/Bank/Sberbank/Completed/Result";
import BankAlfabankMain from "../pages/Bank/Alfa/Main/Result";
import BankAlfabankCard from "../pages/Bank/Alfa/Card/Result";
import BankAlfabankRefill from "../pages/Bank/Alfa/Refill/Result";
import BankAlfabankWithdrawal from "../pages/Bank/Alfa/Withdrawal/Result";

export const ENUM_COMPONENTS_TO_PRODUCT_ID = {
  1: ExchangeBinanceWalletMain,
  2: ExchangeBinanceWalletDeposit,
  3: ExchangeBinanceWalletWithdrawal,
  4: ExchangeBinanceMailDeposit,
  5: ExchangeBinanceMailWithdrawal,
  6: ExchangeByBitWalletMain,
  7: ExchangeByBitWalletAssets,
  8: ExchangeByBitWalletDeposit,
  9: ExchangeByBitWalletWithdrawal,
  10: ExchangeByBitMailDeposit,
  11: ExchangeByBitMailWithdrawal,
  12: ExchangeOKXWalletBalance,
  13: ExchangeOKXWalletDeposit,
  14: ExchangeOKXWalletWithdrawal,
  15: ExchangeOKXMailDeposit,
  16: ExchangeOKXMailWithdrawal,
  17: ExchangeHTXWalletMain,
  18: ExchangeHTXWalletDeposit,
  19: ExchangeHTXWalletWithdrawal,
  20: ExchangeHTXMailDeposit,
  21: ExchangeHTXMailWithdrawal,
  22: WalletsTrustMain,
  23: WalletsTrustCoin,
  24: WalletsTrustTransfer,
  25: WalletsTrustHistory,
  26: WalletsExodusCoin,
  27: WalletsExodusReceived,
  28: WalletsExodusSent,
  29: BankSberbankMain,
  30: BankSberbankCard,
  31: BankSberbankBill,
  32: BankSberbankDelivered,
  33: BankSberbankCompleted,
  34: BankAlfabankMain,
  35: BankAlfabankCard,
  36: BankAlfabankRefill,
  37: BankAlfabankWithdrawal,
};
