import dayjs from "dayjs";
import withPurchase from "../../../../../hoc/withPurchase";
import { HeaderPhoneUi } from "../../../../../components/ui";
import { formattedNumber } from "../../../../../utils/formattedNumber";

const Result = ({ type, state, mobile }) => {
  return (
    <>
      <img
        src={require(`../../../../../assets/img/generator/exchange/binance/mail/deposit//binance-${type}-${state.theme}.png`)}
        className="img-fluid img-thumbnail"
        width="100%"
        alt=""
      />

      <HeaderPhoneUi
        type={type}
        mode={state.theme}
        {...mobile}
        colorBattery={state.theme === "light" ? "#fff" : "#202124"}
      />

      <h4 className="time-arrival">
        [Binance] Ввод средств подтвержден - <br />{" "}
        {state.fullDate
          ? dayjs(state.fullDate).format("YYYY-MM-DD HH:mm:ss")
          : "2024-01-01 00:00:00"}
        (UTC)
        <span>Входящие</span>
      </h4>
      <h6 className="time-mail">
        {state.timeMail ? dayjs(state.timeMail).format("HH:mm") : "00:00"}
      </h6>
      <h5 className="status">Счет успешно пополнен</h5>
      <p className="text">
        Внесенные средства в размере {state.amount || 0} {state.currency} теперь
        доступны на вашем аккаунте Binance. Войдите, чтобы проверить свой
        баланс.{" "}
        <span>
          Если у вас возникли проблемы, ознакомитесь с разделом часто задаваемых
          вопросов.
        </span>
      </p>
      <p className="text v2">
        Вы не инициировали это действие?{" "}
        <span>Пожалуйста, сбросьте пароль</span> и{" "}
        <span>немедленно свяжитесь со службой поддержки.</span>
      </p>
      <p className="text v3">
        Это автоматическое сообщение. Пожалуйста, не отвечайте на него.
      </p>
    </>
  );
};

export default withPurchase(Result, {
  className: "result-img binance mail deposit ",
});
